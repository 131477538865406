<mdb-progress-bar *ngIf="loading" class="progress primary-color-dark" mode="indeterminate"></mdb-progress-bar>

<mdb-stepper *ngIf="!loading" #stepper [linear]="true" [vertical]="true">
    <mdb-step name='Krok 1 - Dane Klienta' [stepForm]="firstFormGroup" label="Uzupełnij informacje dotyczące osoby zawierającej umowę">
        <div class="row">
            <div class="col-12">
                <blockquote class="blockquote bq-warning">
                    <p class="bq-title">Uwaga dotycząca zgłaszania uczestników</p>
                    <p>
                        Klientami mogą być wyłącznie rodzice/opiekunowie prawni uczestnika.
                        Pełnoletni uczestnicy także mogą zarejestrować się samodzielnie. <span style="font-weight: bold">Nie mogą</span> rejestrować się uczestnicy
                        niepełnoletni oraz dalsza rodzina/znajomi rodziców uczestnika.
                    </p>
                    <p>
                        Prosimy pamiętać, że do komunikacji z Klientem będzie wykorzystywany wyłącznie adres email z
                        konta użytkownika systemu Copernicus i telefon wskazany w sekcji "podstawowe dane". Tych
                        informacji nie da się zmienić na dalszych etapach obsługi umowy.
                    </p>
                </blockquote>
            </div>
        </div>
        <form [formGroup]="firstFormGroup">
            <input type="hidden" formControlName="clientEmail">

            <div class="form-row mt-2">
                <div class="col-12">
                    <div class="h6" (click)="showModal()">Podstawowe dane:</div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12 col-md-4">
                    <div class="md-form mt-1 mb-2">
                        <input type="text" id="clientFirstName" formControlName="clientNameFirst" class="form-control" mdbInput mdbValidate>
                        <label for="clientFirstName">Imię</label>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="md-form mt-1 mb-2">
                        <input type="text" id="clientLastName" formControlName="clientNameLast" class="form-control" mdbInput mdbValidate>
                        <label for="clientLastName">Nazwisko</label>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="md-form mt-1 mb-2">
                        <input type="tel" id="clientPhoneNumber" formControlName="clientPhoneNumber" class="form-control" mdbInput mdbValidate>
                        <label for="clientPhoneNumber">numer telefonu</label>
                    </div>
                </div>
            </div>

            <div class="form-row mt-2">
                <div class="col-12 col-md-6">
                    <div class="h6">adres korespondencyjny rodzica/opiekuna:</div>
                    <div class="md-form mb-2 mt-1">
                        <mdb-select [options]="addressOptions" formControlName="mailingAddressID" placeholder="adres korespondencyjny"></mdb-select>
                    </div>
                    <div *ngIf="mailingAddress" class="mb-2 blue-text">
                        {{ mailingAddress.name_first }} {{ mailingAddress.name_last }}
                        <span *ngIf="mailingAddress.is_business">
                            <br/> <i>{{ mailingAddress.company_name }}</i>
                        </span>
                        <br/> {{ mailingAddress.street_name }} {{ mailingAddress.street_number }} <span *ngIf="mailingAddress.flat_number.trim() !== ''"> / {{ mailingAddress.flat_number }}</span>
                        <br/> {{ mailingAddress.zip_code }} {{ mailingAddress.city }}, {{ mailingAddress.country }}
                        <span *ngIf="mailingAddress.is_business"> <br/> NIP {{ mailingAddress.vat_number }}</span>
                        <span *ngIf="mailingAddress.phone_number.trim() !== ''"><br/> tel. {{ mailingAddress.phone_number }}</span>
                    </div>
                    <div *ngIf="!mailingAddress" class="mb-2 red-text">
                        <strong>Proszę wybrać adres korespondencyjny.</strong>
                    </div>
                    <div class="mb-2">
                        <button *ngIf="mailingAddress" mdbBtn type="button" color="primary" rounded="true" outline="true" size="sm" mdbWavesEffect (click)="editAddress(mailingAddress)">edytuj</button>
                        <button mdbBtn type="button" color="success" rounded="true" outline="true" size="sm" mdbWavesEffect (click)="addAddress('MAILING')">nowy</button>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="h6">dane do faktur i dokumentów księgowych:</div>

                    <div class="my-3" [ngClass]="{'participant-signup-noshow' : !sameBillingAddress }" >
                        <mdb-checkbox formControlName="sameBillingAddress" name="checkSameBillingAddress">Taki sam jak adres korespondencyjny</mdb-checkbox>
                    </div>

                    <div [ngClass]="{'participant-signup-noshow' : sameBillingAddress }" >
                        <div class="md-form mb-2 mt-1">
                            <mdb-select [options]="addressOptions" formControlName="billingAddressID" placeholder="adres do rozliczeń"></mdb-select>
                        </div>
                        <div *ngIf="billingAddress" class="mb-2 blue-text">
                            {{ billingAddress.name_first }} {{ billingAddress.name_last }}
                            <span *ngIf="billingAddress.is_business">
                                <br/> <i>{{ billingAddress.company_name }}</i>
                            </span>
                            <br/> {{ billingAddress.street_name }} {{ billingAddress.street_number }} <span *ngIf="billingAddress.flat_number.trim() !== ''"> / {{ billingAddress.flat_number }}</span>
                            <br/> {{ billingAddress.zip_code }} {{ billingAddress.city }}, {{ billingAddress.country }}
                            <span *ngIf="billingAddress.is_business"> <br/> NIP {{ billingAddress.vat_number }}</span>
                            <span *ngIf="billingAddress.phone_number.trim() !== ''"><br/> tel. {{ billingAddress.phone_number }}</span>
                        </div>
                        <div *ngIf="!billingAddress" class="mb-2 red-text">
                            <strong>Proszę wybrać adres do rozliczeń.</strong>
                        </div>
                        <div class="mb-2">
                            <button *ngIf="billingAddress" mdbBtn type="button" color="primary" rounded="true" outline="true" size="sm" mdbWavesEffect (click)="editAddress(billingAddress)">edytuj</button>
                            <button mdbBtn type="button" color="success" rounded="true" outline="true" size="sm" mdbWavesEffect (click)="addAddress('BILLING')">nowy</button>
                        </div>


                        <div class="alert alert-warning" role="alert">
                            <strong>Uwaga!</strong> Po wystawieniu pierwszego dokumentu księgowego nie będzie możliwości
                            zmiany danych na fakturze! Jeżeli korzystasz z dofinansowania z zakładu pracy drugiego rodzica
                            wpisz tutaj dane wymagane przez jego/jej pracodawcę. Jeżeli nie wiesz co wpisać - skontaktuj się z nami.
                        </div>

                    </div>
                </div>
            </div>
        </form>
        <button id="participantSignUpNextStep1" mdbBtn size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>


    <mdb-step name="Krok 2 - Wybór obozu" [stepForm]="secondFormGroup" label="Wybierz z listy obóz, na który chcesz wysłać dziecko">
        <form [formGroup]="secondFormGroup">
            <input type="hidden" formControlName="selectedCampID">
        </form>

        <blockquote class="blockquote bq-warning mt-3">
            <p class="bq-title">Uwaga</p>
            <p>
                Podane ceny obozów nie uwzględniają ewentualnych zniżek przysługujących Klientowi, ani
                kosztów związanych z dodatkowymi usługami (np. transport, nieobowiązkowe ubezpieczenia, itp.). Ostateczna cena uwzględniająca
                Państwa zniżki oraz koszt wybranych przez Państwa opcji dodatkowych zostanie przesłana mailem po rozpatrzeniu
                zgłoszenia przez naszego pracownika. W przypadku jakichkolwiek wątpliwości prosimy o bezpośredni kontakt.
            </p>
        </blockquote>


        <div *ngIf="selectedCamp === null">
            <form [formGroup]="filterCampsFormGroup">
                <div class="row mx-1 mb-2">
                    <div class="col-md-4 col-lg-3 pt-lg-2 d-none d-md-block">
                        Wybierz obozy do wyświetlenia
                    </div>
                    <div class="col-10 col-md-6 col-lg-6">
                        <mdb-select [options]="campGroupNameTagsOptions" formControlName="campTagsFilterSelect" placeholder="Wybierz obozy do pokazania"></mdb-select>
                    </div>
                </div>
            </form>

            <span *ngFor="let camp of decodedCampsValue">
                <div *ngIf="camp['show']" class="row pl-3 pr-3 mb-4 animated fadeIn">
                    <div class="col-xs-5 col-sm-10 col-md-11 col-lg-9 col-xl-8 border border-light rounded-sm z-depth-1">
                        <div class="row p-2 mb-0">
                            <div class="col-12 col-md-8 pl-0 ">
                                <div class="d-flex align-items-center">
                                    <img class="participant-signup-camp-details-logo" src="{{camp.camp_details_public_decoded['camp_owner_icon_small']}}">
                                    <span class="participant-signup-camp-details-camp-name" (click)="chooseCamp(camp)">{{camp.name}}</span>
                                </div>

                                <div class="d-none d-md-flex justify-content-between align-items-center participant-signup-camp-details-left-margin mt-3">
                                    <span class="participant-signup-camp-details-left-camp-date participant-signup-camp-details-camp-date-from">{{ camp.date_start|formatDate: 'DD-MM-YYYY' }}</span>

                                    <div class="participant-signup-camp-details-center-column-container d-none d-md-flex">
                                        <div class="participant-signup-camp-details-hr-container">
                                            <hr>
                                            <span class="participant-signup-camp-details-camp-duration">{{ camp.duration_days }} dni</span>
                                        </div>
                                    </div>

                                    <span class="participant-signup-camp-details-left-camp-date participant-signup-camp-details-camp-date-to participant-signup-camp-details-right-column">{{ camp.date_end|formatDate: 'DD-MM-YYYY' }}</span>
                                </div>

                                <div class="d-none d-md-flex align-items-center justify-content-between participant-signup-camp-details-left-margin mt-1">
                                    <span>
                                        <mdb-icon fas icon="map-marker" class="participant-signup-camp-details-address-pin"></mdb-icon>
                                        <span class="participant-signup-camp-details-address">{{ camp.location_name }}</span>
                                    </span>
                                    <span class="participant-signup-camp-details-right-column participant-signup-camp-details-age">
                                        <mdb-icon fas icon="user" class="participant-signup-camp-details-participant-age"></mdb-icon>
                                        {{ camp.age_min }} - {{ camp.age_max }} lat
                                    </span>
                                </div>



                                <div class="row d-block d-md-none">
                                    <div class="col-12">
                                        <div class="participant-signup-camp-details-left-margin">
                                            <mdb-icon far icon="calendar-alt" class="participant-signup-camp-details-small-icon"></mdb-icon>
                                            <span class="participant-signup-camp-details-text-small">{{ camp.date_start|formatDate: 'DD-MM-YYYY' }} - {{ camp.date_end|formatDate: 'DD-MM-YYYY' }} ({{ camp.duration_days }} dni)</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="participant-signup-camp-details-left-margin">
                                            <mdb-icon fas icon="map-marker" class="participant-signup-camp-details-small-icon pin"></mdb-icon>
                                            <span class="participant-signup-camp-details-text-small location">{{ camp.location_name }}</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="participant-signup-camp-details-left-margin">
                                            <mdb-icon fas icon="user" class="participant-signup-camp-details-small-icon"></mdb-icon>
                                            <span class="participant-signup-camp-details-text-small">{{ camp.age_min }} - {{ camp.age_max }} lat</span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="participant-signup-camp-details-left-margin">
                                            <mdb-icon fas icon="euro-sign" class="participant-signup-camp-details-small-icon price"></mdb-icon>
                                            <span *ngIf="camp.price_special !== null" class="participant-signup-camp-details-price-special small">
                                                <span class="styled-container">
                                                    {{ camp.price }}
                                                </span>
                                            </span>
                                            <span *ngIf="camp.price_special !== null" class="participant-signup-camp-details-text-small price">{{ camp.price }} zł</span>
                                            <span *ngIf="camp.price_special == null" class="participant-signup-camp-details-text-small price">{{ camp.price_special }} zł</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 d-none d-md-flex align-items-end flex-column">
                                <div class="d-flex align-items-start flex-column participant-signup-camp-details-prices-container">
                                    <div class="price-label">
                                        Cena:
                                    </div>
                                    <div class="d-flex flex-row align-items-baseline">
                                        <div *ngIf="camp.price_special !== null" class="participant-signup-camp-details-price-special">
                                            <span class="styled-container">
                                                {{ camp.price }}
                                            </span>
                                        </div>
                                        <div *ngIf="camp.price_special == null" class="participant-signup-camp-details-price">{{ camp.price }} zł</div>
                                        <div *ngIf="camp.price_special !== null" class="participant-signup-camp-details-price">{{ camp.price_special }} zł</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>


        <!--
        <table mdbTable *ngIf="selectedCamp === null" class="w-auto" responsive="true" small="true" hover="true" striped="true">
            <thead>
                <tr>
                    <th class="text-center" style="min-width: 320px;">Nazwa i miejscowość</th>
                    <th class="text-center" style="min-width: 120px;">Data</th>
                    <th class="text-center" style="min-width: 120px;">Wiek</th>
                    <th class="text-center" style="min-width: 120px;">Cena</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let camp of campsValue">
                    <td>
                        <a (click)="showCampDescriptionModal(camp)" class="px-0">{{ camp.name }}</a> <br/>
                        <small>{{ camp.location_name }}</small>
                    </td>
                    <td> {{ camp.date_start|formatDate: 'DD. MMMM' }} <br/> {{ camp.date_end|formatDate: 'DD. MMMM' }}</td>
                    <td> {{ camp.age_min }} - {{ camp.age_max }} lat</td>
                    <td>
                        <span *ngIf="camp.price_special !== null">
                            <span class="camp-signup-oldprice">
                                {{ camp.price }} zł
                            </span>
                            &nbsp; <mdb-badge pill="true" danger="true">PROMOCJA!</mdb-badge>
                            <br/>
                            <strong>{{ camp.price_special }} zł</strong>
                        </span>
                        <span *ngIf="camp.price_special === null">
                                {{ camp.price }} zł
                        </span>
                    </td>
                    <td>
                        <div class="text-center">
                            <button mdbBtn type="button" gradient="peach" rounded="true" mdbWavesEffect size="sm" (click)="chooseCamp(camp)">Wybierz!</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        //-->

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-8 col-lg-6">
                    <mdb-card *ngIf="selectedCamp !== null" class="mb-3 mt-3 border-info" style="max-width: 20rem">
                        <mdb-card-header style="background-color: rgba(0, 0, 0, 0.03)">Wybrany obóz</mdb-card-header>
                        <mdb-card-body class="text-info">
                            <mdb-card-title >
                                <h5>{{ selectedCamp ? selectedCamp.name : '' }}</h5>
                            </mdb-card-title>
                            <mdb-card-text>
                                <p class="h6">Lokalizacja</p>
                                <p>{{ selectedCamp ? selectedCamp.location_name : '' }}</p>
                                <p class="h6">Data</p>
                                <p *ngIf="selectedCamp">{{ selectedCamp.date_start |formatDate: 'DD-MM-YYYY' }}  do {{ selectedCamp.date_end |formatDate: 'DD-MM-YYYY' }}</p>
                                <p class="h6">Cena</p>
                                <p *ngIf="selectedCamp && !selectedCamp.price_special">{{ selectedCamp ? selectedCamp.price : '' }}</p>
                                <p *ngIf="selectedCamp && selectedCamp.price_special"><span class="camp-signup-oldprice">{{ selectedCamp ? selectedCamp.price : '' }}</span> {{ selectedCamp ? selectedCamp.price_special : '' }} zł</p>
                                <div class="text-center mt-4">
                                    <button mdbBtn type="button" color="danger" rounded="true" outline="true" mdbWavesEffect size="sm" (click)="chooseCamp(null)">Zmień wybór</button>
                                </div>
                            </mdb-card-text>
                        </mdb-card-body>
                    </mdb-card>
                </div>
            </div>
        </div>

        <button id="participantSignUpNextStep2" mdbBtn size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>
    <mdb-step name="Krok 3 - Uczestnik" [stepForm]="thirdFormGroup" label="Wybierz uczestnika obozu lub wprowadź jego dane">
        <form [formGroup]="thirdFormGroup">
            <input type="hidden" formControlName="canParticipantBeAssignedToCamp"/>

            <div class="form-row">
                <div class="col-12 col-md-6">
                    <div class="h6 mt-2">uczestnik:</div>
                    <div class="md-form mb-2 mt-1">
                        <mdb-select [options]="participantOptions" formControlName="selectedParticipantID" placeholder="uczestnik"></mdb-select>
                    </div>

                    <div *ngIf="!canParticipantBeSignedUpToCamp(selectedParticipant, selectedCamp)" class="alert alert-warning" role="alert">
                        <h5>Uwaga!</h5>
                        <p>
                            Ten uczestnik jest już zapisany na wybrany obóz!
                        </p>
                    </div>

                    <div *ngIf="selectedParticipant" class="mb-2 blue-text">
                        {{ selectedParticipant.name_first }} {{ selectedParticipant.name_middle }} {{ selectedParticipant.name_last }} <br/>
                        <strong>data ur.</strong> {{selectedParticipant.date_birth}} <strong> &nbsp; &nbsp; PESEL: </strong> {{ selectedParticipant.national_id_number }}<br/>
                        <p class="h6 mt-2">Dane rodziców / opiekunów<br/></p>
                        <span *ngFor="let parent of selectedParticipant.parents_list; let i = index">
                            {{ i + 1 }}. {{ parent.name_full }}, {{ parent.address }}, {{ parent.phone_number }}<br/>
                        </span>

                      <div *ngIf="selectedParticipant.parents_list.length == 1" class="alert alert-warning mt-2" role="alert">
                        <strong>Uwaga!</strong> Uczestnik ma przypisanego tylko jednego rodzica/opiekuna. Prosimy uzupełnić
                        dane (jeśli sa dostępne) naciskając przycisk 'Edytuj' poniżej.
                      </div>
                    </div>

                    <div *ngIf="!selectedParticipant" class="mb-2 red-text">
                        <strong>Proszę wybrać uczestnika z listy.</strong>
                    </div>

                    <div class="mb-2">
                        <button *ngIf="selectedParticipant" mdbBtn type="button" color="primary" rounded="true" outline="true" size="sm" mdbWavesEffect (click)="editParticipant(selectedParticipant)">edytuj</button>
                        <button mdbBtn type="button" color="success" rounded="true" outline="true" size="sm" mdbWavesEffect (click)="addParticipant()">nowy</button>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="h6 mt-2">adres zamieszkania uczestnika:</div>
                    <div class="md-form mb-2 mt-1">
                        <mdb-select [options]="participantAddressOptions" formControlName="participantsAddressID" placeholder="adres uczestnika"></mdb-select>
                    </div>
                    <div *ngIf="participantsAddress" class="mb-2 blue-text">
                        <!-- we don't need to show first and last name here
                        {{ participantsAddress.name_first }} {{ participantsAddress.name_last }}
                        //-->
                        <br/> {{ participantsAddress.street_name }} {{ participantsAddress.street_number }} <span *ngIf="participantsAddress.flat_number.trim() !== ''"> / {{ participantsAddress.flat_number }}</span>
                        <br/> {{ participantsAddress.zip_code }} {{ participantsAddress.city }}, {{ participantsAddress.country }}
                        <span *ngIf="participantsAddress.phone_number.trim() !== ''"><br/> tel. {{ participantsAddress.phone_number }}</span>
                    </div>
                    <div *ngIf="!participantsAddress" class="mb-2 red-text">
                        <strong>Proszę wybrać adres zamieszkania uczestnika.</strong>
                    </div>
                    <div class="mb-2">
                        <button *ngIf="participantsAddress" mdbBtn type="button" color="primary" rounded="true" outline="true" class="mt-3" size="sm" mdbWavesEffect (click)="editAddress(participantsAddress)">edytuj</button>
                        <button mdbBtn type="button" color="success" rounded="true" outline="true" class="mt-3" size="sm" mdbWavesEffect (click)="addAddress('PARTICIPANT')">nowy</button>
                    </div>
                </div>
            </div>
        </form>

        <button id="participantSignUpNextStep3" mdbBtn class="mt-3" size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>
    <mdb-step name="Krok 4 - Dodatkowe informacje" [stepForm]="fourthFormGroup" label="Dieta, uczulenia, wskazówki dla kadry, szczepienia">
        <form [formGroup]="fourthFormGroup">
            <div class="form-row">
                <div class="col-12">
                    <p class="mt-3">
                        Istotne dane o stanie zdrowia uczestnika, rozwoju psychofizycznym
                        (np. czy uczestnik jest uczulony, a jeżeli tak, to na co i jakie są objawy alergii;
                        czy uczestnik cierpi na chorobę przewlekłą lub nawracającą chorobę lub jest w trakcie
                        diagnozowania, a jeżeli tak, to na jaką chorobę i jakie są jej objawy; jak uczestnik znosi
                        jazdę samochodem; czy uczestnik przyjmuje stale leki, a jeżeli tak, to jakie i w jakich dawkach;
                        czy uczestnik przechodził zabiegi chirurgiczne, a jeżeli tak, to kiedy i z jakiego powodu;
                        czy uczestnik nosi aparat ortodontyczny lub okulary lub soczewki)
                    </p>
                    <div class="md-form">
                        <textarea type="text" id="healthIssues" class="md-textarea md-textarea-auto form-control" mdbInput formControlName="healthIssues" maxlength="2000"></textarea>
                        <label for="healthIssues">
                            Wpisz uwagi dotyczące zdrowia uczestnika
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12">
                    <p class="mt-3">
                        Informacja o specjalnych potrzebach edukacyjnych Uczestnika, w szczególności o potrzebach
                        wynikających z niepełnosprawności, niedostosowania społecznego lub zagrożenia niedostosowaniem
                        społecznym.
                    </p>
                    <div class="md-form">
                        <textarea type="text" id="specialNeeds" class="md-textarea md-textarea-auto form-control" mdbInput formControlName="specialNeeds" maxlength="2000"></textarea>
                        <label for="specialNeeds">
                            Wpisz uwagi dotyczące specjalnych potrzeb uczestnika
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12">
                    <p class="mt-3">
                        Informacja o specjalnych potrzebach dietetycznych (indywidualna dieta oferowana jest w ograniczonym
                        zakresie lub jako opcja dodatkowo płatna - zależy to od ośrodka oraz możliwości indywidualnej
                        personalizacji zamówienia)
                    </p>
                    <div class="md-form">
                        <textarea type="text" id="dietaryNeeds" class="md-textarea md-textarea-auto form-control" mdbInput formControlName="dietaryNeeds" maxlength="2000"></textarea>
                        <label for="dietaryNeeds">
                            Wpisz uwagi dotyczące wymagań/ograniczeń dietetycznych uczestnika
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12">
                    <p>
                        Zdrowie i bezpieczeństwo Uczestników obozów AlphaCamp jest dla nas absolutnym priorytetem. Dlatego
                        od wszystkich kandydatów wymagamy posiadania obowiązkowych szczepień. Listę szczepień obowiązkowych oraz
                        ich kalendarz znajdą Państwo m.in. na stronie Głównego Inspektoratu Sanitarnego
                        (<a href="https://www.gov.pl/web/gis/szczepienia-obowiazkowe" target="_blank" class="m-0 p-0">tutaj</a>).
                    </p>
                    <p>
                        Prosimy o podanie roku wykonania poszczególnych szczepień oraz przesłanie skanu książeczki potwierdzającej
                        wykonanie szczepień obowiązkowych. W przypadku braku szczepienia proszę wpisać BRAK.
                    </p>
                </div>
            </div>
            <div class="form-row">
                <div class="col-6 col-md-3">
                    <div class="md-form">
                        <input type="text" id="vaccination_DTaP" formControlName="vaccination_DTaP" class="form-control" mdbInput mdbValidate>
                        <label for="vaccination_DTaP">błonica</label>
                    </div>
                </div>

                <div class="col-6 col-md-3">
                    <div class="md-form">
                        <input type="text" id="vaccination_IPV" formControlName="vaccination_IPV" class="form-control" mdbInput mdbValidate>
                        <label for="vaccination_IPV">tężec</label>
                    </div>
                </div>

                <!--
                <div class="col-6 col-md-3">
                    <div class="md-form">
                        <input type="text" id="vaccination_MMR" formControlName="vaccination_MMR" class="form-control" mdbInput mdbValidate>
                        <label for="vaccination_MMR">MMR (odra, świnka, różyczka)</label>
                    </div>
                </div>
                //-->

                <input type="hidden" id="vaccination_MMR" formControlName="vaccination_MMR" class="form-control" mdbInput mdbValidate value="nie dotyczy">

                <div class="col-6 col-md-6">
                    <div class="md-form">
                        <input type="text" id="vaccination_other" formControlName="vaccination_other" class="form-control" mdbInput mdbValidate>
                        <label for="vaccination_other">inne</label>
                    </div>
                </div>
            </div>
        </form>
        <button id="participantSignUpNextStep4" mdbBtn class="mt-3" size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>
    <mdb-step name="Krok 5 - Transport" label="Wybierz sposób dojazdu dziecka na obóz" [stepForm]="fifthFormGroup" >
        <blockquote class="blockquote bq-warning mt-3">
            <p class="bq-title">Uwaga</p>
            <p>
                Koszt transportu zostanie doliczony do ceny obozu, ale dla Państwa wygody dajemy możliwość zmiany decyzji nawet na 14 dni przed rozpoczęciem turnusu
                - w takim przypadku koszty transportu zostaną zaktualizowane.
            </p>
            <p>
                Podany koszt transportu nie uwzględnia ceny biletów PKP (o ile taka forma podróży została wybrana).
            </p>
        </blockquote>

        <form [formGroup]="fifthFormGroup">
            <div class="mt-2 mb-2">
                Podróż na obóz:
            </div>

            <span *ngIf="transportOptionsTo && transportOptionsTo.length">
                <div *ngFor="let transportOption of transportOptionsTo; let i = index" class="form-check">
                    <input type="radio" class="form-check-input" [id]="'radioTransportOption_to' + i" [value]="transportOption.code"
                           formControlName="radioTransportOptionTo" name="radioTransportOptionTo">
                    <label class="form-check-label" [for]="'radioTransportOption_to' + i" [mdbTooltip]="transportOption.long_description" placement="top">
                        {{ transportOption.short_description }} - cena: {{ transportOption.price|currency: 'PLN':'code':'1.2-2':'pl-PL'}}
                    </label>
                </div>
            </span>

            <div class="mb-2 mt-2">
                Podróż powrotna:
            </div>

            <span *ngIf="transportOptionsFrom && transportOptionsFrom.length">
                <div *ngFor="let transportOption of transportOptionsFrom; let i = index" class="form-check">
                    <input type="radio" class="form-check-input" [id]="'radioTransportOption_from' + i" [value]="transportOption.code"
                           formControlName="radioTransportOptionFrom" name="radioTransportOptionFrom">
                    <label class="form-check-label" [for]="'radioTransportOption_from' + i" [mdbTooltip]="transportOption.long_description" placement="top">
                        {{ transportOption.short_description }} - cena: {{ transportOption.price|currency: 'PLN':'code':'1.2-2':'pl-PL'}}
                    </label>
                </div>
            </span>
        </form>
        <button id="participantSignUpNextStep5" mdbBtn class="mt-3" size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>
    <mdb-step name="Krok 6 - Ubezpieczenie" label="Wybór dodatkowych, nieobowiązkowych opcji ubezpieczenia" [stepForm]="sixthFormGroup">
        <blockquote class="blockquote bq-warning mt-3">
            <p class="bq-title">Uwaga</p>
            <p>
                Uczestnicy obozów AlphaCamp objęci są obowiązkowym ubezpieczeniem NNW, którego koszt wliczony jest w cenę
                obozu. Warunki ubezpieczenia znajdą Państwo [<a href="https://www.alphacamp.pl/dokumenty-lato/" target="_blank" class="m-0 p-0">tutaj</a>].
            </p>
            <p>
                Na tej stronie mogą Państwo wybrać dodatkowe - płatne - opcje ubezpieczenia. Przed dokonaniem wyboru
                prosimy o zapoznanie się z warunkami umowy. AlphaCamp jest
                jedynie pośrednikiem zawierającym umowę z ubezpieczycielem w Państwa imieniu - wysokość składki,
                zakres ubezpieczenia i warunki wypłaty odszkodowań ustala wyłącznie ubezpieczyciel.
            </p>
            <p>
                Więcej informacji na temat ubezpieczeń znajdą Państwo [<a href="https://www.alphacamp.pl/dokumenty-lato/" target="_blank" class="m-0 p-0">tutaj</a>].
            </p>
        </blockquote>

        <p *ngIf="!insuranceOptions || !insuranceOptions.length">
            Dla wybranego obozu nie są dostępne żadne dodatkowe opcje ubezpieczenia.
        </p>

        <form [formGroup]="sixthFormGroup">
            <span *ngIf="insuranceOptions && insuranceOptions.length">
                <div formArrayName="checkInsuranceOptions" *ngFor="let insuranceOption of insuranceOptions; let i = index;">
                    <mdb-checkbox id=`{{insuranceOption.key}}` [id]="insuranceOption.value" [formControl]="getInsuranceOptionControlAtIndex(i)" [name]="'test_' + i">{{ insuranceOption.label }}</mdb-checkbox>
                </div>
            </span>
        </form>


        <button  id="participantSignUpNextStep6" mdbBtn class="mt-3" size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>

    <mdb-step name="Krok 7 - Uwagi, kody promo" label="Wpisz kody rabatowe i skorzystaj ze zniżek" [stepForm]="seventhFromGroup">
        <form [formGroup]="seventhFromGroup">
            <div class="form-row">
                <div class="col-12">
                    <div class="md-form">
                        <input type="text" id="promo_codes" formControlName="promo_codes" class="form-control" mdbInput mdbValidate>
                        <label for="promo_codes">Kody promocyjne</label>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <p>
                        <strong>Uwaga dla rodziców rejestrujących więcej niż jedno dziecko.</strong>
                    </p>
                    <p>
                        Aby skorzystać z promocyjnej ceny prosimy wpisać poniżej imiona i nazwiska pozostałych uczestników -
                        własnych dzieci lub osób poleconych. Takie dane należy przesłać na formularzach zgłoszeniowych wszystkich
                        uczestników objętych promocją.
                    </p>
                </div>
            </div>
            <div class="form-row">
                <div class="col-12">
                    <div class="md-form">
                        <textarea type="text" id="general_remarks" class="md-textarea md-textarea-auto form-control" mdbInput formControlName="general_remarks" maxlength="2000"></textarea>
                        <label for="general_remarks">
                            W tym miejscu wpisz dodatkowe uwagi dla osoby obsługującej Twoje zgłoszenie (np. ustalenia telefoniczne)
                        </label>
                    </div>
                </div>
            </div>
        </form>
        <button  id="participantSignUpNextStep7" mdbBtn class="mt-3" size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>

    <mdb-step name="Krok 8 - Akceptacja regulaminu" label="Zapoznaj się z regulaminem" [stepForm]="eighthFormGroup">
        <blockquote class="blockquote bq-warning mt-3">
            <p class="bq-title">Uwaga</p>
            <p>
                Aby złożyć zamówienie niezbędne jest zapoznanie się z treścią regulaminu i zaakceptowanie jego warunków.
            </p>
            <p>
                Pełna treść regulaminu świadczenia usług turystycznych - obozów naukowych AlphaCamp znajduje się poniżej.
                W przypadku jakichkolwiek uwag lub wątpliwości prosimy o bezpośredni kontakt. Aby zaakceptować regulamin
                i złożyć zamówienie, proszę zaznaczyć odpowiednie pole znajdujące się u dołu strony.
            </p>
        </blockquote>
        <form [formGroup]="eighthFormGroup">
            <div class="form-row">
                <div class="col-12">
                    <mdb-checkbox formControlName="checkDataIsTrueStatement">
                        Niniejszym oświadczam, że wszystkie dane podane w interaktywnym formularzu Zapisu są prawdziwe. <span class="red-text" style="font-weight: bold">*</span>
                    </mdb-checkbox>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12">
                    <mdb-checkbox formControlName="checkTermsAndConditionsAccepted">
                        Niniejszym oświadczam, że zapoznałam/zapoznałem  się z treścią <strong>Regulaminu</strong>
                        (pełna treść Regulaminu dostępna
                        <strong><a href="https://www.alphacamp.pl/wp-content/uploads/legal_documents/2020-01-13%20regulamin.pdf" target="_blank" class="p-0">tutaj</a></strong>)
                        i akceptuję jego warunki. <span class="red-text" style="font-weight: bold">*</span>
                    </mdb-checkbox>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12">
                    <mdb-checkbox formControlName="checkRODOAccepted">
                        Niniejszym oświadczam, że zapoznałam/zapoznałem się z treścią Polityki Ochrony Danych Osobowych
                        Organizatora (pełna treść Polityki Ochrony Danych Osobowych Organizator dostępna
                        <strong><a href="https://www.alphacamp.pl/rodo/" target="_blank" class="p-0">tutaj</a></strong>)
                        i wyrażam zgodę na przetwarzanie przez Organizatora danych osobowych podanych w interaktywnym
                        formularzu Zapisu wskazanych w Karcie Kwalifikacyjnej w celu zapewnienia bezpieczeństwa i ochrony
                        zdrowia uczestników Obozów AlphaCamp (wzór Karty Kwalifikacyjnej dostępny
                        <strong><a href="https://www.alphacamp.pl/wp-content/uploads/legal_documents/2020-01-28%20Karta%20Kwalifikacyjna.pdf" target="_blank" class="p-0">tutaj</a></strong>)
                        <span class="red-text" style="font-weight: bold">*</span>
                    </mdb-checkbox>
                </div>
            </div>

            <div class="form-row">
                <div class="col-12">
                    <mdb-checkbox formControlName="checkElectronicInvoiceAccepted">
                        Niniejszym wyrażam zgodę na stosowanie faktur elektronicznych w przypadku zawarcia Umowy.
                        W przypadku braku zgody na stosowanie faktur elektronicznych, prosimy o bezpośredni kontakt z
                        Organizatorem na adres poczty elektronicznej Organizatora: <a href="mailto:info@alphacamp.pl" class="p-0">info@alphacamp.pl</a>.
                    </mdb-checkbox>
                </div>
            </div>
        </form>
        <button  id="participantSignUpNextStep8" mdbBtn class="mt-3" size="sm" color="primary" (click)="stepper.next()">Następny krok</button>
    </mdb-step>
    <mdb-step name="Koniec" label="Sprawdź dane wpisane w formularzu i wyślij zgłoszenie!">
        <blockquote class="blockquote bq-success mt-3">
            <p class="bq-title">Ostatni krok przed Tobą!</p>
            <p>
                Dziękujemy za Twój czas i wypełnienie formularza. Upewnij się, że wszytkie dane są poprawne (możesz wrócić
                do poprzednich kroków klikając na ich etykiety).
            </p>
            <p>
                Jeżeli wszystko się zgadza - naciśnij przycisk 'Wyślij zgłoszenie'. Następnie sprawdź skrzynkę email -
                wyślemy na nią komplet niezbędnych dokumentów.
            </p>
            <p>
                Witamy na obozach AlphaCamp!
            </p>
        </blockquote>
        <div class="step-actions">
            <button id="participantSignUpFinalSubmission" *ngIf="!savingData" mdbBtn color="success" class="mt-3" size="sm" (click)="onSubmit()">Wyślij zgłoszenie</button>
            <button *ngIf="savingData" mdbBtn color="info" outline="true" rounded="true" block="true" class="btn my-4 waves-effect z-depth-0" mdbWavesEffect disabled>
                <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                Copernicus analizuje Twoje dane...
            </button>
        </div>
    </mdb-step>
</mdb-stepper>

<div mdbModal #campDescriptionModal class="modal fade top" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-notify modal-info modal-lg" role="document">
        <!--Content-->
        <div class="modal-content">
            <!--Header-->
            <div class="modal-header">
                <p class="heading lead">Opis obozu</p>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="campDescriptionModal.hide()">
                    <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <!--Body-->
            <div class="modal-body">
                <h2 class="h3-responsive">{{ campDetails.name }}</h2>
                <hr>
                <p class="lead"> {{ campDetails.description_short }} </p>
                <p> {{ campDetails.description_full }}</p>
            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect (click)="campDescriptionModal.hide()">Zamknij</a>
            </div>
        </div>
        <!--/.Content-->
    </div>
</div >
