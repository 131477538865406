import {Injectable, OnDestroy, OnInit} from '@angular/core';

import {BehaviorSubject, Observable, of, Subject, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';

import {User} from '../_models';
import {Apollo} from "apollo-angular";
import {UserByIdResult, UserLoginResult} from "../graphQL/results";
import {getUserByIdQuery, userLoginQuery} from "../graphQL/queries";
import {JSONHandlerStatuses, LoginResult} from "../types";


@Injectable({providedIn: 'root'})
export class AuthenticationService {
	public currentUser: Observable<User>;
	private currentUserSubject: BehaviorSubject<User>;
	private currentUserSubscription: Subscription;

	constructor(private apollo: Apollo) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable();

		//we want to subscribe to current user changes, but doing it in constructors causes problems - i.e.
		//some essential components don't get reference to working AuthenticationService
		//short time after bootstrapping the service we subscribe to user changes, meanwhile all dependent components
		//can use AuthenticationService's funcionality
		setTimeout(() => {
			this.subscribeToCurrentUser();
		}, 1000);
	}

    public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	public get currentJWTToken(): string {
		//don't return token if user is empty
		if (this.currentUserSubject.value !== null && localStorage.getItem('token')) {
			return localStorage.getItem('token');
		} else return null;
	}

  /**
   * This method subscribes to changes of current user's data entity. This will allow for the whole interface to
   * get automatically updated whenever user updates his own data.
   */
	private subscribeToCurrentUser() {
	  //unsubscribe from current subscription - if any
      if (this.currentUserSubscription){
        this.currentUserSubscription.unsubscribe();
        this.currentUserSubscription = null;
      }

      //leave if we're not logged in
      if (!this.currentUserValue) return;

      this.currentUserSubscription = this.apollo.watchQuery<UserByIdResult>({
        query: getUserByIdQuery,
        variables: {
          id: this.currentUserValue.id
        }
      })
          .valueChanges
          .subscribe(( { data }) => {
            localStorage.setItem('currentUser', JSON.stringify(data.user));
            this.currentUserSubject.next(data.user);
          });
    }

	login(email: string, password: string): Observable<LoginResult> {
		let login_result: Subject<LoginResult> = new Subject<LoginResult>();

		let userLoginSubscription = this.apollo.watchQuery<UserLoginResult>({
			query: userLoginQuery,
			variables: {
				email,
				password,
			}
		})
		.valueChanges
		.pipe(
			map(result => {
				userLoginSubscription.unsubscribe();

				let retVal: LoginResult;

				if (result.data.user_login.error !== null) {
					retVal = {
						status: 'ERROR',
						message: ''
					};

					if (result.data.user_login.error.startsWith(JSONHandlerStatuses.ERROR_EMAIL_DOESNT_EXIST)) {
						retVal.message = '<p>Niepoprawny adres email.</p>' +
							'<p>Aby skorzystać z systemu Copernicus musisz najpierw założyć konto.</p>' +
							'<p>Użyj linku "zarejestruj się".</p>';
					} else if (result.data.user_login.error.startsWith(JSONHandlerStatuses.ERROR_ACCOUNT_NOT_ACTIVE)) {
						retVal.message = '<p>Twoje konto jest nieaktywne.</p>' +
							'<p>Jeżeli nie aktywowałeś(-łaś) konta po rejestracji, znajdź w skrzynce email wiadomość z odpowiednim ' +
							'linkiem.</p>' +
							'<p>Mniej prawdopodobne jest, że konto zostało zablokowane - w takiej sytuacji skontaktuj się z nami.</p>';
					} else if (result.data.user_login.error.startsWith(JSONHandlerStatuses.ERROR_WRONG_PASSWORD)) {
						retVal.message = '<p>Niepoprawne hasło lub email.</p>' +
							'<p>Spróbuj jeszcze raz.</p>';
					} else {
						retVal.message = 'Podczas logowania wystąpił błąd - spróbuj jeszcze raz.';
					}
				} else if (result.data.user_login.token !== null && result.data.user_login.user !== null) {
					localStorage.setItem('token', result.data.user_login.token);
					localStorage.setItem('currentUser', JSON.stringify(result.data.user_login.user));

					this.currentUserSubject.next(result.data.user_login.user);

					this.subscribeToCurrentUser();

					retVal = {
						status: 'OK',
						message: ''
					};
				} else {
					retVal = {
						status: 'ERROR',
						message: 'Podczas logowania wystąpił błąd - spróbuj jeszcze raz.'
					};
				}

				login_result.next(retVal);
			})
		)
		.subscribe();

		return login_result.asObservable();
	}

	logout() {
		// remove user from local storage to log user out
		localStorage.removeItem('token');
		localStorage.removeItem('currentUser');

		//inform all listeners about the change
		this.currentUserSubject.next(null);

		//stop listening to changes of current subject
		if (this.currentUserSubscription)
        {
          this.currentUserSubscription.unsubscribe();
          this.currentUserSubscription = null;
        }

		location.reload(true);
	}
}
