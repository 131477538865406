import { deleteAddressesMutation, updateOrCreateAddressMutation } from "../mutations";
import { getUserAddressesQuery } from "../queries";
import {Address, Address_EmptyEntity, AddressType, Participant, User} from "../../_models";
import { Apollo } from "apollo-angular";
import {AddressInput, AddressInputLiteral} from "../inputs";
import * as _ from "underscore";
import {ModalAddressEditComponent} from "../../modal-address-edit";
import {Tools} from "../../_helpers/tools";
import {MDBModalRef, MDBModalService} from "ng-uikit-pro-standard";
import {Observable, Subject, Subscription} from "rxjs";
import {Injectable} from "@angular/core";
import {ModalAddressSelectComponent} from "../../modal-address-select/modal-address-select.component";
import {NetworkOperationResult} from "../../types";
import {updateOrCreateAddressMutationResult, UserAddressesQueryResult} from "../results";

@Injectable({providedIn: "root"})
export class AddressDataManipulations {
  constructor(private apollo: Apollo, private modalService: MDBModalService) {
  }

	addAddress(addressData: AddressInput, expectedResponseData: any, userId: number): Observable<NetworkOperationResult>{
		let retVal = new Subject<NetworkOperationResult>();

    // Previously we've had an optimistic response here, but since we need to do some checks in the backend, we can't do it anymore.
		let localSub = this.apollo.mutate<updateOrCreateAddressMutationResult>({
			mutation: updateOrCreateAddressMutation,
			variables: {
				address: addressData
			},
			update: (proxy, { data: { updateOrCreateAddress }}) => {
        // we must use update function to update the cache, bacause newly created address isn't present
        // in userAddresses query result, so we must add it manually

        if (updateOrCreateAddress.status.startsWith('Error: ')){
          // if there was an error, we don't want to update the cache and insert new address into it
          return;
        }

				// Read the data from our cache for this query.
				const data = proxy.readQuery({ query: getUserAddressesQuery, variables: { user_id: userId } });
				let dataCopy = JSON.parse(JSON.stringify(data));    // data['user_addresses'] is immutable so we must make a DEEP clone of data!

				// Add our address from the mutation to the end.
				dataCopy['user_addresses'].push(updateOrCreateAddress.address);

				// Write our data back to the cache.
				proxy.writeQuery({ query: getUserAddressesQuery, variables: { user_id: userId }, data: dataCopy });
			},
		}).subscribe(
			({ data }) => {
        if (data.updateOrCreateAddress.status.startsWith('Error: ')){
          retVal.next({
            status: "ERROR",
            message: "Dodanie nowego adresu nie powiodło się.<br/>" +
              "Sprawdź czy wszystkie dane są poprawne (w tym nazwa kraju i format kodu pocztowego).<br/>" +
              "Jeśli problem będzie się powtarzał, skontaktuj się z naszym biurem."
          });
        }
        else {
          retVal.next({
            status: "SUCCESS",
            message: "Pomyślnie dodano nowy adres.",
            data: data.updateOrCreateAddress.address
          });
        }

        retVal.complete();
        localSub.unsubscribe();
			},
			error => {
        retVal.next({
          status: "ERROR",
          message: "Dodanie nowego adresu nie powiodło się. Wystąpił błąd sieci lub nieoczekiany błąd na serwerze.\r\n" +
            "Jeśli problem będzie się powtarzał, skontaktuj się z naszym biurem."
        });

        retVal.complete();
				localSub.unsubscribe();
			}
		);

		return retVal.asObservable();
	}

	editAddress(addressData: AddressInput, expectedResponseData: any, userId: number) : Observable<NetworkOperationResult> {
    let retVal = new Subject<NetworkOperationResult>();

    const localSub = this.apollo.mutate<updateOrCreateAddressMutationResult>({
			mutation: updateOrCreateAddressMutation,
			variables: {
				address: addressData
			},
      /*
      We can't use optimistic response here, because we need to check if the address was saved correctly on the server.

			optimisticResponse: {
				__typename: 'Mutation',
				updateOrCreateAddress: {
					...expectedResponseData,
					__typename: 'Address'
				},
			},
      */
		}).subscribe(
      ({ data }) => {
        if (data.updateOrCreateAddress.status.startsWith('Error: ')){
          retVal.next({
            status: "ERROR",
            message: "Aktualizacja adresu nie powiodła się.<br/>" +
              "Sprawdź czy wszystkie dane są poprawne (w tym nazwa kraju i format kodu pocztowego).<br/>" +
              "Jeśli problem będzie się powtarzał, skontaktuj się z naszym biurem."
          });
        }
        else {
          retVal.next({
            status: "SUCCESS",
            message: "Pomyślnie poprawiono adres.",
            data: data.updateOrCreateAddress.address
          });
        }

        retVal.complete();
        localSub.unsubscribe();
      },
      error => {
        retVal.next({
          status: "ERROR",
          message: "Edycja danych adresu nie powiodło się. Wystąpił błąd sieci lub nieoczekiany błąd na serwerze.\r\n" +
            "Jeśli problem będzie się powtarzał, skontaktuj się z naszym biurem."
        });

        retVal.complete();
        localSub.unsubscribe();
      }
    );

    return retVal.asObservable();
	}

	deleteAddress(addressId: number, userId: number){
		// if addressId is a string, convert it to a number
    if (typeof addressId === 'string'){
      addressId = parseInt(addressId);
    }

    this.apollo.mutate({
			mutation: deleteAddressesMutation,
			variables: {
				deleteIDs: [addressId]
			},
			optimisticResponse: {
				__typename: 'Mutation',
				deleteAddresses: [addressId],
			},
			update: (proxy, { data: { deleteAddresses }}) => {
				// Read the data from our cache for this query.
				let data: object = proxy.readQuery({ query: getUserAddressesQuery, variables: { user_id: userId } });

				//create a hash object which will have deleted ids as its keys
				const idsHash = deleteAddresses.reduce((a, b) => {a[b] = ''; return a}, {});

        if (data){
          //now leave only those addresses which haven't been deleted
          let newAddresses = {
            ...data,
            user_addresses:  data['user_addresses'].filter((address: Address) => {
              return !idsHash.hasOwnProperty(address.id);
            })
          }

          // Write our data back to the cache.
          proxy.writeQuery({ query: getUserAddressesQuery, variables: { user_id: userId }, data: newAddresses });
        }

			},
		}).subscribe();
	}

	editAddressDialog(address: Address){
		/*
			  Configure the modal
		*/
		const modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				address: _.clone(address),
				saveButtonPressed: false,  //very important!!!
        error_message: null
			}
		};

		/*
			  Show the modal
		*/
		let modalRef = this.modalService.show(ModalAddressEditComponent, modalOptions);

    const subscriptionMethod = () => {
      /*
			  Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		*/
      let addressEditSubscription = this.modalService.closed.subscribe(() => {
        if (modalRef.content.saveButtonPressed){
          //save address to the database
          //make sure to copy and set only fields present in AddressInput interface of GraphQL throws an error
          const tools = new Tools();
          const extractAddressInput = tools.extract<AddressInput>(AddressInputLiteral);
          let data = extractAddressInput(modalRef.content.address);
          data.user_id = modalRef.content.address.user.id;

          let resultSubscription = this.editAddress(data, modalRef.content.address, data.user_id)
            .subscribe(
              (data) => {
                // check for errors
                if (data.status === "ERROR"){
                  // set error message
                  modalOptions.data.error_message = data.message;

                  // open the dialog again and leave
                  modalRef = this.modalService.show(ModalAddressEditComponent, modalOptions);

                  // re-subscribe to the modal
                  addressEditSubscription.unsubscribe(); // first unsubscribe from the previous subscription
                  subscriptionMethod();

                  // just leave the function
                  return;
                }
              }
            );
        }

        // don't react to further events coming from the modal
        addressEditSubscription.unsubscribe();
      });
    }

    subscriptionMethod();
	}

  selectNewOrUpdatedAddressDialog(addressType: AddressType, addressOwner: number, currentlySelectedAddressId?: number, replaceNameWith?: string) : Observable<NetworkOperationResult>{
    let retVal = new Subject<NetworkOperationResult>();

    let dialogTitle: string;
    let addressTypeName: string;
    let dialogMessage: string;

    switch (addressType){
      case AddressType.MAILING:
        dialogTitle = 'Wybierz adres korespondencyjny';
        addressTypeName = AddressType.MAILING;
        break;

      case AddressType.BILLING:
        dialogTitle = 'Wybierz adres do rozliczeń';
        addressTypeName = AddressType.BILLING;
        break;

      case AddressType.PARTICIPANT:
        dialogTitle = 'Wybierz adres uczestnika';
        addressTypeName = AddressType.PARTICIPANT;
        break;
    }

    /*
      Dialogs are backend agnostic, so we need to provide all necessary data to the dialog before showing it.
     */
    let addressesSubscription : Subscription = this.apollo.watchQuery<UserAddressesQueryResult>({
      query: getUserAddressesQuery,
      fetchPolicy: "network-only",
      variables: {
        user_id: addressOwner
      }
    })
      .valueChanges
      .subscribe(
        ({data, loading}) => {
          // hanlde data only once
          addressesSubscription.unsubscribe();

          /*
            Configure the modal
           */
          let modalOptions = {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: false,
            ignoreBackdropClick: false,
            class: 'modal-dialog-scrollable modal-notify modal-info',
            containerClass: '',
            animated: true,
            data: {
              saveButtonPressed: false,
              dialogTitle: dialogTitle,
              addressTypeName: addressTypeName,
              defaultAddressID: currentlySelectedAddressId,
              replaceAddressNameWith: replaceNameWith,
              dialogMessage: dialogMessage,
              addresses: data.user_addresses,
            }
          };

          /*
            Show the modal
           */
          let modalRef = this.modalService.show(ModalAddressSelectComponent, modalOptions);
          let addressSelectSubscription = this.modalService.closed.subscribe(() => {
            if (modalRef.content.saveButtonPressed){
              retVal.next({status: "CONFIRMED", message: 'Wybrano nowy adres.', data: modalRef.content.selectedAddressID});
              retVal.complete();
            }
            else {
              retVal.next({status: "OK", message: 'Anulowano wybór adresu.'});
              retVal.complete();
            }
          });

        },
        (error) => {
          retVal.next({status: "ERROR", message: 'Nie udało się zmienić adresu użytkownika.'});
          retVal.complete();
        },
        () => {
          addressesSubscription.unsubscribe();
          retVal.complete();
        });

    return retVal.asObservable();
  }

	addAddressDialog(owner: User): Observable<Address>{
		let retVal = new Subject<Address>();

		/*
			Prepare an empty address object ready for editing
		*/
		const newEmptyAddress = {
			..._.clone(Address_EmptyEntity),
			user: owner
		};

		/*
			Configure the modal
		 */
		let modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: false,
			class: 'modal-lg modal-dialog-scrollable',
			containerClass: '',
			animated: true,
			data: {
				address: newEmptyAddress,
				saveButtonPressed: false,
        error_message: null
			}
		};

		/*
			Show the modal
		 */
		let modalRef : MDBModalRef = this.modalService.show(ModalAddressEditComponent, modalOptions);

    const subscriptionMethod = () => {
      /*
			Subscribe to modal 'closed' event. Remember to unsubscribe after a single 'closed' event handling!!!
		 */
      const addressEditSubscription = this.modalService.closed.subscribe(() => {
        if (modalRef.content.saveButtonPressed){
          // save address to the database

          // make sure to copy and set only fields present in AddressInput interface of GraphQL throws an error
          const tools = new Tools();
          const extractAddressInput = tools.extract<AddressInput>(AddressInputLiteral);
          let data = extractAddressInput(modalRef.content.address);
          const user_id = modalRef.content.address.user.id;
          data.user_id = user_id;

          let resultSubscription = this.addAddress(data, modalRef.content.address, user_id)
            .subscribe(
              (data) => {
                // check for errors
                if (data.status === "ERROR"){
                  // add error message to the modal
                  modalOptions.data.error_message = data.message;

                  // open the dialog again and leave
                  modalRef = this.modalService.show(ModalAddressEditComponent, modalOptions);

                  // re-subscribe to the modal
                  addressEditSubscription.unsubscribe(); // first unsubscribe from the previous subscription
                  subscriptionMethod();

                  // just leave the function
                  return;
                }

                retVal.next(data.data);
                retVal.complete();
              }
            );
        }
        else {
          // cancel o close button were pressed
          retVal.next(null);
          retVal.complete();
        }

        //don't react to further events coming from the modal
        addressEditSubscription.unsubscribe();
      });
    }

    subscriptionMethod();

		return retVal.asObservable();
	}
}
