<!--Double navigation-->
<header>
  <!-- Sidebar navigation -->
  <mdb-side-nav #sidenav class="fixed sn-bg-alphacamp" [fixed]="true">

    <mdb-navbar-brand>
      <!-- Logo -->
      <li>
        <div class="logo-wrapper waves-light">
          <!-- <a href="#"><img src="https://mdbootstrap.com/img/logo/mdb-transparent.png" class="img-fluid flex-center"></a> -->
        </div>
      </li>
      <!--/. Logo -->
    </mdb-navbar-brand>

    <links>
      <!--Social-->
      <li>
        <ul class="social">
          <li><a class="icons-sm fb-ic" href="http://www.facebook.com/alphacamppl" target="_blank"><i class="fab fa-facebook-f" title="Facebook"> </i></a></li>
          <li><a class="icons-sm " href="http://www.instagram.com/alphacamppl" target="_blank"><i class="fab fa-instagram" title="Instagram"> </i></a></li>
          <li><a class="icons-sm " href="http://www.youtube.com/alphacamppl" target="_blank"><i class="fab fa-youtube" title="YouTube"> </i></a></li>
        </ul>
      </li>
      <!--/Social-->

      <!--Search Form-->
      <!--
      <li>
        <form class="search-form" role="search">
          <div class="form-group md-form mt-0 pt-1" mdbWavesEffect>
            <input type="text" class="form-control" placeholder="Szukaj">
          </div>
        </form>
      </li>
      /-->
      <!--/.Search Form-->

      <!-- Side navigation links -->
      <li>
        <ul class="collapsible collapsible-accordion">
          <mdb-accordion [multiple]="false" aria-multiselectable="false">

            <!-- Simple link -->
            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head routerLink="/" mdbWavesEffect><i class="fas fa-home"></i> Pulpit</mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>


            <!-- Collapsible link -->
            <mdb-accordion-item *ngIf="currentUser.is_manager || currentUser.is_admin">
              <mdb-accordion-item-head mdbWavesEffect><i class="fas fa-tachometer-alt"></i> Administracja
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li><a routerLink="/allusers" routerLinkActive="active" mdbWavesEffect>Użytkownicy</a></li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <!-- Collapsible link -->
            <mdb-accordion-item *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
              <mdb-accordion-item-head mdbWavesEffect><i class="far fa-chart-bar"></i> Biuro
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li><a [routerLink]="['/reservations', 'admin', 'SUBMITTED']" routerLinkActive="active" mdbWavesEffect>Zgłoszenia</a></li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <!-- Collapsible link -->
            <mdb-accordion-item *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee || isNaukowcowDwochManager()">
              <mdb-accordion-item-head mdbWavesEffect><i class="far fa-file-excel"></i> Raporty
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li>
                    <a routerLinkActive="active" (click)="downloadMaturalniReport()" mdbWavesEffect>Maturzyści</a>
                  </li>
                  <li *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
                    <a routerLinkActive="active" (click)="downloadAllActiveParticipantsReport()" mdbWavesEffect>Wszyscy uczestnicy</a>
                  </li>
                  <li *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
                    <a routerLinkActive="active" (click)="downloadIssuedInvoicesReport()" mdbWavesEffect>Wystawione faktury</a>
                  </li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>


            <!-- Collapsible link -->
            <mdb-accordion-item *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
              <mdb-accordion-item-head mdbWavesEffect><i class="fas fa-dollar-sign"></i> Płatności
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li><a mdbWavesEffect>Podsumowanie</a></li>
                  <li><a mdbWavesEffect>Przeterminowane</a></li>
                  <li><a mdbWavesEffect>Nadchodzące</a></li>
                  <li><a mdbWavesEffect [routerLink]="['/payments']" routerLinkActive="active">Przelewy</a></li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>


            <!-- Collapsible link -->
            <mdb-accordion-item *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
              <mdb-accordion-item-head mdbWavesEffect><i class="fas fa-hospital-symbol"></i> Ubezpieczenia
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li><a mdbWavesEffect [routerLink]="['/reservation-insurance-browser', 'all', 'all']" routerLinkActive="active">Przeglądarka ubezpieczeń</a></li>
                  <li><a mdbWavesEffect [routerLink]="['/insurancesubmissions']">Wysłane zgłoszenia</a></li>
                  <li><a mdbWavesEffect [routerLink]="['/generateinsurancesubmission']">Generator zgłoszeń</a></li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>


             <!-- Collapsible link -->
            <mdb-accordion-item *ngIf="currentUser.is_admin || currentUser.is_manager || currentUser.is_backoffice_employee">
              <mdb-accordion-item-head mdbWavesEffect><i class="fas fa-futbol"></i> Obozy
              </mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <ul>
                  <li><a mdbWavesEffect [routerLink]="['/allcamps']" routerLinkActive="active">Lista obozów</a></li>
                </ul>
                <ul>
                  <li><a mdbWavesEffect [routerLink]="['/activecampsstats']" routerLinkActive="active">Statystyki</a></li>
                </ul>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <!-- Simple link -->
            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head [routerLink]="['/myparticipants']" routerLinkActive="active" mdbWavesEffect>
                <i class="fas fa-users"></i> Moi uczestnicy
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <!-- Simple link -->
            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head [routerLink]="['/myaddresses']" routerLinkActive="active" mdbWavesEffect>
                <i class="far fa-address-card"></i> Moje adresy
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>


            <!-- Simple link -->
            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head [routerLink]="['/reservations', 'client', 'ACTIVE']" routerLinkActive="active" mdbWavesEffect>
                <i class="fas fa-user-plus"></i> Moje rezerwacje
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>

            <!-- Simple link -->
            <!--
            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head mdbWavesEffect>
                <i class="fas fa-money-bill-wave-alt"></i> Płatności
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>
            -->

            <!-- Simple link -->
            <mdb-accordion-item class="no-collase">
              <mdb-accordion-item-head mdbWavesEffect routerLink="/participantsignup" routerLinkActive="active">
                <i class="far fa-plus-square"></i> <strong>Zapisz uczestnika</strong>
              </mdb-accordion-item-head>
              <mdb-accordion-item-body></mdb-accordion-item-body>
            </mdb-accordion-item>


          </mdb-accordion>
        </ul>
      </li>
      <!--/. Side navigation links -->
    </links>
    <div class="sidenav-bg mask-strong"></div>
  </mdb-side-nav>
  <!--/. Sidebar navigation -->

  <!-- Navbar -->
  <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav"
              [containerInside]="false">

    <navlinks class="navbar-container">
      <!-- SideNav slide-out button -->
      <div class="float-left">
        <a (click)="sidenav.show()" class="button-collapse"><i class="fas fa-bars"></i></a>
      </div>
      <!--/. SideNav slide-out button -->
    </navlinks>

    <mdb-navbar-brand>
      <!-- Breadcrumb-->
      <div class="breadcrumbs breadcrumb-dn mr-auto">
        <p>Copernicus</p>
      </div>
      <!--/. Breadcrumb-->
    </mdb-navbar-brand>

    <navlinks>
      <ul class="nav navbar-nav nav-flex-icons ml-auto ie-double-nav">
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect><i class="fas fa-envelope"></i> &nbsp;
            <span class="clearfix d-none d-sm-inline-block">Kontakt</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link waves-light" mdbWavesEffect><i class="far fa-comments"></i> &nbsp;
            <span class="clearfix d-none d-sm-inline-block">Wsparcie</span>
          </a>
        </li>
        <li class="nav-item dropdown btn-group" dropdown>
          <a dropdownToggle type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
            <i class="fas fa-user"></i> &nbsp;
            <span id="loggedUserMenuName" class="clearfix d-none d-sm-inline-block">{{ currentUser.firstName }} &nbsp;</span>
          </a>
          <div class="dropdown-menu dropdown-primary dropdown-menu-right" role="menu">
            <a class="dropdown-item" [routerLink]="['/myaddresses']" mdbWavesEffect>Zarządzaj adresami</a>
            <a class="dropdown-item" [routerLink]="['/myparticipants']" mdbWavesEffect>Zarządzaj uczestnikami</a>
            <div class="divider dropdown-divider"></div>
            <a id="logout" class="dropdown-item" (click)="logout()">Wyloguj</a>
          </div>
        </li>
      </ul>
    </navlinks>
  </mdb-navbar>
  <!--/. Navbar -->

</header>
<!--/.Double navigation-->

<!--Main Layout-->
<main class="mx-2">
  <div class="container-fluid mt-0 p-0">
    <alert></alert>
    <router-outlet></router-outlet>
  </div>
</main>
<!--/Main layout-->
