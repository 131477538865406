import gql from 'graphql-tag';

export const userRegisterMutation = gql`mutation user_register($email: Email!, $password: String!, $firstName: String!, $lastName: String!) {
	user_register(email: $email, password: $password, firstName: $firstName, lastName: $lastName){
		status
		error
	}
}`;

export const userConfirmAccountMutation = gql`mutation user_confirm_account($confirmationCode: String!) {
	user_confirm_account(confirmationCode: $confirmationCode){
		status
		error
	}
}`;

export const userResetPasswordMutation = gql`mutation user_reset_password($email: Email!, $resetCode: String, $newPassword: String){
	user_reset_password(email: $email, resetCode: $resetCode, newPassword: $newPassword){
		status
	}
}`;

export const updateUsersBasicFieldsMutation = gql`mutation updateUser($updatedData: BasicUsersFieldsInput!) {
      updateUsersBasicFields(userData: $updatedData){
        id
        firstName
        lastName
        email
        phone_number
        is_confirmed
        is_active
        last_login_timestamp
        billing_address {
          id
        }
        mailing_address {
          id
        }
        is_admin
        is_manager
        is_backoffice_employee
        is_camp_employee
        is_client
      }
    }`;

export const updateUsersAdminFieldsMutation = gql`mutation updateUser($updatedData: AdminUsersFieldsInput!) {
      updateUsersAdminFields(userData: $updatedData){
        id
        firstName
        lastName
        email
        phone_number
        is_confirmed
        is_active
        last_login_timestamp
        billing_address {
          id
        }
        mailing_address {
          id
        }
        is_admin
        is_manager
        is_backoffice_employee
        is_camp_employee
        is_client
      }
    }`;

export const updateOrCreateAddressMutation = gql`mutation updateAddress($address: AddressInput!) {
      updateOrCreateAddress(address: $address){
        status
        address {
          id
          user {
            id
          }
          is_business
          name_first
          name_last
          company_name
          street_name
          street_number
          flat_number
          zip_code
          city
          country
          vat_number
          phone_number
        }
      }
    }`;

export const deleteAddressesMutation = gql`mutation deleteAddresses($deleteIDs: [Int]!){
	deleteAddresses(deleteIDs: $deleteIDs)
}`;

export const updateOrCreateParticipantMutation = gql`mutation updateParticipant($participant: ParticipantInput!) {
      updateOrCreateParticipant(participant: $participant){
		id
		user {
			id
		}
		name_first
		name_middle
		name_last
		date_birth
		national_id_number
		gender
		parents_list {
			name_full
			status
			phone_number
			address
		}
		camp_ids
      }
    }`;

export const deleteParticipantsMutation = gql`mutation deleteParticipants($deleteIDs: [Int]!){
	deleteParticipants(deleteIDs: $deleteIDs)
}`;

export const signUpParticipantToCampMutation = gql`mutation signUpParticipantToCamp(
	$participant_id: ID!,
	$camp_id: ID!,
	$client_phone_number: String!,
	$clients_mailing_address_id: ID!,
	$clients_billing_address_id: ID!,
	$participants_address_id: ID!,
	$client_name_full: String!,
	$health_issues: String,
	$dietary_needs: String,
	$special_needs: String,
	$vaccinations_snapshot: JSON!,
	$promo_codes: String,
	$general_remarks: String,
	$terms_accepted: Boolean!,
	$rodo_accepted: Boolean!,
	$data_is_true_statement: Boolean!,
	$electronic_invoice_accepted: Boolean!,
	$transport_options: SelectedTransportInput!,
	$insurance_snapshot: JSON!,
	$price: Money!
){
	signUpParticipantToCamp(
		participant_id: $participant_id,
		camp_id: $camp_id,
		client_phone_number: $client_phone_number,
		clients_mailing_address_id: $clients_mailing_address_id,
		clients_billing_address_id: $clients_billing_address_id,
		participants_address_id: $participants_address_id,
		client_name_full: $client_name_full,
		health_issues: $health_issues,
		dietary_needs: $dietary_needs,
		special_needs: $special_needs,
		vaccinations_snapshot: $vaccinations_snapshot,
		promo_codes: $promo_codes,
		general_remarks: $general_remarks,
		terms_accepted: $terms_accepted,
		rodo_accepted: $rodo_accepted,
		data_is_true_statement: $data_is_true_statement,
		electronic_invoice_accepted: $electronic_invoice_accepted,
		transport_options: $transport_options,
		insurance_snapshot: $insurance_snapshot,
		price: $price
	){
		camp_participant{
			id
			participant {
				id
				user {
					id
					phone_number
				}
			}
			camp {
				id
			}
			payment_plan_entries {
		        id
		        date_due
		        amount
		        amount_left
		        events_array
		      }
			clients_mailing_address {
				id
			}
			clients_mailing_address_snapshot
			clients_billing_address {
				id
			}
			clients_billing_address_snapshot
			participants_address {
				id
			}
			participants_address_snapshot

			health_issues {
				value
			}
			dietary_needs {
				value
			}
			special_needs {
				value
			}
			promo_codes {
				value
			}
			general_remarks {
				value
			}
			accommodation {
				value
			}
			authorizations {
				value
			}

			terms_accepted
			created_timestamp
			status
			contract_sign_type
			contract_sign_date
			vaccinations_snapshot
			insurance_snapshot
			payment_plan_snapshot
			events_array
			price
		}
		participant {
			id
			camp_ids
		}

	}
}`;

export const cancelCampReservationMutation = gql`mutation cancelCampReservation($camp_participant_id: ID!){
	cancelCampReservation(camp_participant_id: $camp_participant_id){
		deletedID
		updatedParticipant {
			id
			camp_ids
		}
	}
}`;

export const editReservationSelectedTransportOptions = gql`mutation editSelectedTransportOptions(
	$camp_participant_id: ID!, $updated_transport_options: SelectedTransportInput!){
		editReservationSelectedTransportOptions(camp_participant_id: $camp_participant_id, updated_transport_options: $updated_transport_options){
			status
			camp_participant{
				id
				selected_transport_options{
					to {
						code
						price
						remarks
					}
					from {
						code
						price
						remarks
					}
				}
				price
				price_extra_options
				total_price
				price_extra_components {
					INSURANCE
					TRANSPORTATION
				}
				is_data_confirmed
				pending_changes
				events_array
			}
		}
}`;

export const editReservationInsurancePoliciesMutation = gql`
mutation editReservationInsurancePolicies($camp_participant_id: ID!, $policies_to_assign: [IdPricePairInputType],
			$assign_comment: String, $policies_to_delete: [ID], $delete_comment: String){
	editReservationInsurancePolicies(camp_participant_id:$camp_participant_id, policies_to_assign: $policies_to_assign,
	assign_comment: $assign_comment, policies_to_delete: $policies_to_delete, delete_comment:$delete_comment){
    id
    price
    price_extra_options
    price_extra_components {
	    INSURANCE
	    TRANSPORTATION
	}
	is_data_confirmed
	pending_changes
    events_array
    insurance_policies {
      id
      client_price
      camp_participant {
        id
      }
      insurance_policy {
        id
        name
        description
        issuing_company_name
      }
      submission_document {
        id
      }
    }
  }
}`;

export const editReservationPriceMutation = gql`
mutation editReservationPrice($camp_participant_id: ID!, $new_price: Money!, $comment: String!){
	editReservationPrice(camp_participant_id:$camp_participant_id, new_price: $new_price, comment:$comment){
		status
		camp_participant{
		    id
		    price
		    price_extra_options
		    payment_plan_entries {
				        id
				        date_due
				        amount
				        amount_left
				        events_array
				      }
		    payment_plan_snapshot
		    is_data_confirmed
			pending_changes
		    events_array
	    }
  }
}`;

export const changeReservationStatusMutation = gql`
mutation changeReservationStatus($camp_participant_id: ID!, $new_status: Reservation_Status!, $comment: String!){
  changeReservationStatus(camp_participant_id: $camp_participant_id, new_status: $new_status, comment: $comment){
    status
    camp_participant {
      id
      status
      is_data_confirmed
	  pending_changes
      events_array
    }
  }
}
`;

export const updateReservationIsDataConfirmedMutation = gql`mutation update_reservation_is_data_confirmed($camp_participant_id: ID!, $is_data_confirmed: Boolean!){
  updateReservationIsDataConfirmed(camp_participant_id: $camp_participant_id, is_data_confirmed: $is_data_confirmed){
    status
    camp_participant {
      id
      is_data_confirmed
      events_array
    }
  }
}`;

export const regenerateReservationContractDocumentsMutation = gql`
mutation regenerateReservationContractDocuments($camp_participant_id: ID!, $comment: String!){
	regenerateReservationContractDocuments(camp_participant_id: $camp_participant_id, comment: $comment){
		status
		camp_participant {
			id
			is_data_confirmed
			pending_changes
			events_array
		}
	}
}`;

export const clientSignContractOnlineMutation = gql`
mutation clientSignContractOnline($camp_participant_id: ID!, $confirmation_code: String!){
  clientSignContractOnline(camp_participant_id: $camp_participant_id, confirmation_code: $confirmation_code){
    status
    camp_participant {
      id
      status
      contract_sign_type
      contract_sign_date
      payment_plan_entries {
        id
        date_due
        amount
        amount_left
        events_array
      }
      documents_to_submit {
            document_id
            document_name
            document_required
            document_required_original
            document_submitted_status
            document_submitted_date
            comment
       }
      is_data_confirmed
	  pending_changes
      events_array
    }
  }
}
`;

export const newReservationPaymentMutation = gql`
mutation newReservationPayment($camp_participant_id: ID!, $amount: Money!, $transaction_timestamp: Date!, $is_income: Boolean!, $bank_account: String!, $method: PaymentMethod!, $status: PaymentStatus!, $payment_plan_entries_updates: [InstallmentPaymentInput]!){
  newReservationPayment(camp_participant_id: $camp_participant_id, amount: $amount, transaction_timestamp: $transaction_timestamp, is_income: $is_income, bank_account: $bank_account, method: $method, status: $status, payment_plan_entries_updates: $payment_plan_entries_updates){
    status

    camp_participant{
      id
      payment_plan_entries {
        id
        amount
        amount_left
      }

      payments {
        id
        amount
        transaction_timestamp
        bank_account
        status
        method
        invoice_number
        invoice_type
        created_timestamp
      }

      events_array
    }
  }
}`;

export const issueInvoiceForPaymentMutation = gql`
mutation issueInvoiceForPayment($payment_id: ID!, $invoice_type: InvoiceType_Enum!){
  issueInvoiceForPayment(payment_id: $payment_id, invoice_type: $invoice_type){
    payment{
      id
      invoice_number
      invoice_type
    }
    status
  }
}`;

export const issueOrderForReservationMutation = gql`
mutation issueOrderForReservation($camp_participant_id: ID!){
  issueOrderForReservation(camp_participant_id: $camp_participant_id){
    status
    camp_participant {
      id
      order_number
    }
  }
}`;


export const issueInsuranceSubmissionMutation = gql`mutation issueInsuranceSubmission($insurancepolicy_id: ID!, $id_type: Insurance_Submission_ID_Source_Enum!, $ids: [ID]!){
	issueInsuranceSubmission(insurancepolicy_id: $insurancepolicy_id, id_type: $id_type, ids: $ids){
		status
		file
		insurance_submission {
			id
			insurance_policy {
				id
			}
			created_timestamp
			template_used
			events_array
		}
		updatedParticipantPolicies{
			id
			submission_document {
				id
			}
		}
	}
}`;

export const sendEmailEnrolledReminderMutation = gql`mutation sendEmailEnrolledReminder($camp_participant_id: ID!, $message: String){
  sendEmailEnrolledReminder(camp_participant_id: $camp_participant_id, message: $message){
    status
    camp_participant {
      id
      events_array
    }
  }
}`;

export const rollbackReservationStatusToSubmittedMutation = gql`mutation rollbackReservationStatusToSubmitted ($camp_participant_id: ID!, $comment: String!){
  rollbackReservationStatusToSubmitted(camp_participant_id: $camp_participant_id, comment: $comment){
    status
    camp_participant{
      id
      status
      contract_sign_date
      contract_sign_type
      price_extra_options
      events_array
      insurance_policies{
        id
      }
      payments{
        id
      }
      payment_plan_entries{
        id
      }
      price_extra_components {
        INSURANCE
        TRANSPORTATION
      }
    }
  }
}`;

export const sendEmailConfirmAccountReminderMutation = gql`mutation sendEmailConfirmAccountReminder($user_id: ID!, $comment: String){
  sendEmailConfirmAccountReminder(user_id: $user_id, comment: $comment){
    status
    user {
      id
      events_array
    }
  }
}`;

/*
export const toggleConfirmDocumentsReceived = gql`mutation toggleConfirmDocumentsReceived($camp_participant_id: ID!, $document_type: Document_Type!, $comment: String!){
  toggleConfirmDocumentsReceived(camp_participant_id: $camp_participant_id, document_type: $document_type, comment: $comment){
    status
    camp_participant {
      id
      enrollment_card_received_date
      vaccinations_book_received_date
      events_array
    }
  }
}`;
*/

export const regenerateReservationPaymentPlanMutation = gql`mutation regenerateReservationPaymentPlan($camp_participant_id: ID!, $update_payment_plan_snapshot: Boolean!, $comment: String!){
  regenerateReservationPaymentPlan(camp_participant_id: $camp_participant_id, update_payment_plan_snapshot: $update_payment_plan_snapshot, comment: $comment){
    status
    camp_participant{
      id
      payment_plan_snapshot
      payment_plan_entries {
        id
        date_due
        amount
        amount_left
      }
      is_data_confirmed
	  pending_changes
      events_array
    }

  }
}`;

export const updateReservationsDocumentsToSubmitFromCampConfigMutation = gql`mutation updateReservationsDocumentsToSubmitFromCampConfig($camp_participant_id: ID!){
  updateReservationsDocumentsToSubmitFromCampConfig(camp_participant_id: $camp_participant_id){
    status
    camp_participant{
      id
      documents_to_submit{
        document_id
        document_name
        document_required
        document_required_original
        document_submitted_status
        document_submitted_date
        comment
      }
      is_data_confirmed
	  pending_changes
      events_array
    }
  }
}`;

export const updateReservationsDocumentsToSubmitMutation = gql`mutation updateReservationsDocumentsToSubmit($camp_participant_id: ID!, $documents_to_submit: [DocumentToSubmitInpupt]!){
  updateReservationsDocumentsToSubmit(camp_participant_id: $camp_participant_id, documents_to_submit: $documents_to_submit){
    status
    camp_participant{
      id
      documents_to_submit{
        document_id
        document_name
        document_required
        document_required_original
        document_submitted_status
        document_submitted_date
        comment
      }
      is_data_confirmed
	  pending_changes
      events_array
    }
  }
}`;

export const addOrEditExtraInvoiceMutation = gql`mutation add_or_edit_extra_invoice($camp_participant_id: ID!, $provider: String!, $invoice_foreign_id: String!, $invoice_status: ExtraInvoice_Status, $invoice_include_in_summary: Boolean, $comment: String){
	addOrEditExtraInvoice(camp_participant_id: $camp_participant_id, provider: $provider, invoice_foreign_id: $invoice_foreign_id, invoice_status: $invoice_status, invoice_include_in_summary: $invoice_include_in_summary, comment: $comment){
		status
		camp_participant {
			id

			extra_invoices {
				id
				created_timestamp
				provider
				invoice_number
				invoice_foreign_id
				invoice_date_issued
				invoice_date_due
				invoice_amount_total
				invoice_amount_paid
				invoice_include_in_summary
				invoice_status
				invoice_items
				comment
			}
			extra_invoices_total_amount
			extra_invoices_amount_left
		}
	}
}`;

export const autoUpdateExtraInvoiceMutation = gql`mutation autoUpdateExtraInvoice($camp_participant_id: ID!,$invoice_id: String!){
  autoUpdateExtraInvoice(camp_participant_id: $camp_participant_id, invoice_id: $invoice_id){
    status
    camp_participant {
      id
      is_data_confirmed
	  pending_changes

      extra_invoices {
        id
        created_timestamp
        provider
        invoice_number
        invoice_foreign_id
        invoice_date_issued
        invoice_date_due
        invoice_amount_total
        invoice_amount_paid
        invoice_include_in_summary
        invoice_status
        invoice_items
        comment
      }
      extra_invoices_total_amount
      extra_invoices_amount_left
    }
  }
}`;

export const deleteExtraInvoiceMutation = gql`mutation deleteExtraInvoice($camp_participant_id: ID!,$invoice_id: String!){
  deleteExtraInvoice(camp_participant_id: $camp_participant_id, invoice_id: $invoice_id){
    status
    camp_participant {
      id
      is_data_confirmed
	  pending_changes

      extra_invoices {
        id
        created_timestamp
        provider
        invoice_number
        invoice_foreign_id
        invoice_date_issued
        invoice_date_due
        invoice_amount_total
        invoice_amount_paid
        invoice_include_in_summary
        invoice_status
        invoice_items
        comment
      }
      extra_invoices_total_amount
      extra_invoices_amount_left
    }
  }
}`;

export const issueChangeRequestMutation = gql`mutation change_request($camp_participant_id: ID!, $field_name: String!, $requested_changes: String!){
	issue_change_request_to_reservations_descriptive_field(camp_participant_id: $camp_participant_id, field_name: $field_name, requested_changes: $requested_changes){
	    status
	    camp_participant{
	      id
	      health_issues {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      dietary_needs {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      special_needs {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      general_remarks {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      promo_codes {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      accommodation {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      authorizations {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      selected_transport_options {
            change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }

          is_data_confirmed
		  pending_changes
	      events_array
	    }
    }
}`;

export const editReservationsDescriptiveFieldMutation = gql`mutation edit_reservations_descriptive_field($camp_participant_id: ID!, $field_name: String!, $new_value: String!){
	edit_reservations_descriptive_field(camp_participant_id: $camp_participant_id, field_name: $field_name, new_value: $new_value){
	    status
	    camp_participant{
	      id
	      health_issues {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      dietary_needs {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      special_needs {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      general_remarks {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      promo_codes {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      accommodation {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      authorizations {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }

	      is_data_confirmed
		  pending_changes
	      events_array
	    }
    }
}`;

export const deleteReservationsDescriptiveFieldChangeRequestMutation = gql`mutation delete_reservations_descriptive_field_change_request($camp_participant_id: ID!, $field_name: String!, $request_id: String!){
	delete_reservations_descriptive_field_change_request(camp_participant_id: $camp_participant_id, field_name: $field_name, request_id: $request_id){
	    status
	    camp_participant{
	      id
	      health_issues {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      dietary_needs {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      special_needs {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      general_remarks {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      promo_codes {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      accommodation {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      authorizations {
	        value
	        change_requests {
	          id
	          date_created
	          user_id
	          user_full_name
	          requested_changes
	        }
	      }
	      selected_transport_options {
            change_requests {
               id
	           date_created
	           user_id
	           user_full_name
	           requested_changes
             }
           }

          is_data_confirmed
		  pending_changes
	      events_array
	    }
    }
}`;

export const updateReservationsParticipantInfoRequestMutation = gql`mutation update_reservations_participant_info_request($camp_participant_id: ID!) {
  update_reservations_participant_info_request(camp_participant_id: $camp_participant_id){
    status
    camp_participant{
      id
      participant_snapshot
      events_array
    }
  }
}`;

export const changeReservationAddressMutation = gql`mutation changeReservationAddress($camp_participant_id: ID!, $new_address_id: ID!, $address_type: Address_Type!){
  changeReservationAddress(camp_participant_id: $camp_participant_id, new_address_id: $new_address_id, address_type: $address_type){
    status
    camp_participant{
      id
      clients_billing_address {
        id
      }
      clients_billing_address_snapshot
      clients_mailing_address {
        id
      }
      clients_mailing_address_snapshot
      participants_address {
        id
      }
      participants_address_snapshot
      events_array
    }
  }
}`;

export const Toggle_reservation_attachment_acceptanceMutation = gql`
  mutation toggle_reservation_attachment_acceptance($camp_participant_id: ID!, $key: String!)
  {
    toggle_reservation_attachment_acceptance(camp_participant_id: $camp_participant_id, key: $key)
    {
      status
      camp_participant {
          id
          unaccepted_attachments
          attachments {
            id
            key
            file_name
            date_created
            size
            accepted
          }
          events_array
        }
    }
  }`;

export const Delete_reservation_attachmentMutation = gql`mutation
  delete_reservation_attachment($camp_participant_id: ID!, $key: String!, $comment: String)
  {
    delete_reservation_attachment(camp_participant_id: $camp_participant_id, key: $key, comment: $comment)
    {
      status
      camp_participant {
          id
          unaccepted_attachments
          attachments {
            id
            key
            file_name
            date_created
            size
            accepted
          }
          events_array
        }
    }
  }`;
