import {User} from "./user";

// enum list containing address types
export enum AddressType {
  BILLING = 'BILLING',
  MAILING = 'MAILING',
  PARTICIPANT = 'PARTICIPANT',
}

export const CountriesSelectConfig = [
  {
    "value": "AFGANISTAN",
    "label": "Afganistan"
  },
  {
    "value": "ALBANIA",
    "label": "Albania"
  },
  {
    "value": "ALGIERIA",
    "label": "Algieria"
  },
  {
    "value": "ANDORA",
    "label": "Andora"
  },
  {
    "value": "ANGOLA",
    "label": "Angola"
  },
  {
    "value": "ANGUILLA",
    "label": "Anguilla"
  },
  {
    "value": "ANTARKTYKA",
    "label": "Antarktyka"
  },
  {
    "value": "ANTIGUA I BARBUDA",
    "label": "Antigua i Barbuda"
  },
  {
    "value": "ARABIA SAUDYJSKA",
    "label": "Arabia Saudyjska"
  },
  {
    "value": "ARGENTYNA",
    "label": "Argentyna"
  },
  {
    "value": "ARMENIA",
    "label": "Armenia"
  },
  {
    "value": "ARUBA",
    "label": "Aruba"
  },
  {
    "value": "AUSTRALIA",
    "label": "Australia"
  },
  {
    "value": "AUSTRIA",
    "label": "Austria"
  },
  {
    "value": "AZERBEJDżAN",
    "label": "Azerbejdżan"
  },
  {
    "value": "BAHAMY",
    "label": "Bahamy"
  },
  {
    "value": "BAHRAJN",
    "label": "Bahrajn"
  },
  {
    "value": "BANGLADESZ",
    "label": "Bangladesz"
  },
  {
    "value": "BARBADOS",
    "label": "Barbados"
  },
  {
    "value": "BELGIA",
    "label": "Belgia"
  },
  {
    "value": "BELIZE",
    "label": "Belize"
  },
  {
    "value": "BENIN",
    "label": "Benin"
  },
  {
    "value": "BERMUDY",
    "label": "Bermudy"
  },
  {
    "value": "BHUTAN",
    "label": "Bhutan"
  },
  {
    "value": "BIAłORUś",
    "label": "Białoruś"
  },
  {
    "value": "BOLIWIA",
    "label": "Boliwia"
  },
  {
    "value": "BONAIRE, SINT EUSTATIUS I SABA",
    "label": "Bonaire, Sint Eustatius i Saba"
  },
  {
    "value": "BOśNIA I HERCEGOWINA",
    "label": "Bośnia i Hercegowina"
  },
  {
    "value": "BOTSWANA",
    "label": "Botswana"
  },
  {
    "value": "BRAZYLIA",
    "label": "Brazylia"
  },
  {
    "value": "BRUNEI",
    "label": "Brunei"
  },
  {
    "value": "BRYTYJSKIE TERYTORIUM OCEANU INDYJSKIEGO",
    "label": "Brytyjskie Terytorium Oceanu Indyjskiego"
  },
  {
    "value": "BRYTYJSKIE WYSPY DZIEWICZE",
    "label": "Brytyjskie Wyspy Dziewicze"
  },
  {
    "value": "BUłGARIA",
    "label": "Bułgaria"
  },
  {
    "value": "BURKINA FASO",
    "label": "Burkina Faso"
  },
  {
    "value": "BURUNDI",
    "label": "Burundi"
  },
  {
    "value": "CHILE",
    "label": "Chile"
  },
  {
    "value": "CHINY",
    "label": "Chiny"
  },
  {
    "value": "CHORWACJA",
    "label": "Chorwacja"
  },
  {
    "value": "CURAçAO",
    "label": "Curaçao"
  },
  {
    "value": "CYPR",
    "label": "Cypr"
  },
  {
    "value": "CZAD",
    "label": "Czad"
  },
  {
    "value": "CZARNOGóRA",
    "label": "Czarnogóra"
  },
  {
    "value": "CZECHY",
    "label": "Czechy"
  },
  {
    "value": "DALEKIE WYSPY MNIEJSZE STANóW ZJEDNOCZONYCH",
    "label": "Dalekie Wyspy Mniejsze Stanów Zjednoczonych"
  },
  {
    "value": "DANIA",
    "label": "Dania"
  },
  {
    "value": "DEMOKRATYCZNA REPUBLIKA KONGA",
    "label": "Demokratyczna Republika Konga"
  },
  {
    "value": "DOMINIKA",
    "label": "Dominika"
  },
  {
    "value": "DOMINIKANA",
    "label": "Dominikana"
  },
  {
    "value": "DżIBUTI",
    "label": "Dżibuti"
  },
  {
    "value": "EGIPT",
    "label": "Egipt"
  },
  {
    "value": "EKWADOR",
    "label": "Ekwador"
  },
  {
    "value": "ERYTREA",
    "label": "Erytrea"
  },
  {
    "value": "ESTONIA",
    "label": "Estonia"
  },
  {
    "value": "ETIOPIA",
    "label": "Etiopia"
  },
  {
    "value": "FALKLANDY",
    "label": "Falklandy"
  },
  {
    "value": "FIDżI",
    "label": "Fidżi"
  },
  {
    "value": "FILIPINY",
    "label": "Filipiny"
  },
  {
    "value": "FINLANDIA",
    "label": "Finlandia"
  },
  {
    "value": "FRANCJA",
    "label": "Francja"
  },
  {
    "value": "FRANCUSKIE TERYTORIA POłUDNIOWE I ANTARKTYCZNE",
    "label": "Francuskie Terytoria Południowe i Antarktyczne"
  },
  {
    "value": "GABON",
    "label": "Gabon"
  },
  {
    "value": "GAMBIA",
    "label": "Gambia"
  },
  {
    "value": "GEORGIA POłUDNIOWA I SANDWICH POłUDNIOWY",
    "label": "Georgia Południowa i Sandwich Południowy"
  },
  {
    "value": "GHANA",
    "label": "Ghana"
  },
  {
    "value": "GIBRALTAR",
    "label": "Gibraltar"
  },
  {
    "value": "GRECJA",
    "label": "Grecja"
  },
  {
    "value": "GRENADA",
    "label": "Grenada"
  },
  {
    "value": "GRENLANDIA",
    "label": "Grenlandia"
  },
  {
    "value": "GRUZJA",
    "label": "Gruzja"
  },
  {
    "value": "GUAM",
    "label": "Guam"
  },
  {
    "value": "GUERNSEY",
    "label": "Guernsey"
  },
  {
    "value": "GUJANA FRANCUSKA",
    "label": "Gujana Francuska"
  },
  {
    "value": "GUJANA",
    "label": "Gujana"
  },
  {
    "value": "GWADELUPA",
    "label": "Gwadelupa"
  },
  {
    "value": "GWATEMALA",
    "label": "Gwatemala"
  },
  {
    "value": "GWINEA BISSAU",
    "label": "Gwinea Bissau"
  },
  {
    "value": "GWINEA RóWNIKOWA",
    "label": "Gwinea Równikowa"
  },
  {
    "value": "GWINEA",
    "label": "Gwinea"
  },
  {
    "value": "HAITI",
    "label": "Haiti"
  },
  {
    "value": "HISZPANIA",
    "label": "Hiszpania"
  },
  {
    "value": "HOLANDIA",
    "label": "Holandia"
  },
  {
    "value": "HONDURAS",
    "label": "Honduras"
  },
  {
    "value": "HONGKONG",
    "label": "Hongkong"
  },
  {
    "value": "INDIE",
    "label": "Indie"
  },
  {
    "value": "INDONEZJA",
    "label": "Indonezja"
  },
  {
    "value": "IRAK",
    "label": "Irak"
  },
  {
    "value": "IRAN",
    "label": "Iran"
  },
  {
    "value": "IRLANDIA",
    "label": "Irlandia"
  },
  {
    "value": "ISLANDIA",
    "label": "Islandia"
  },
  {
    "value": "IZRAEL",
    "label": "Izrael"
  },
  {
    "value": "JAMAJKA",
    "label": "Jamajka"
  },
  {
    "value": "JAPONIA",
    "label": "Japonia"
  },
  {
    "value": "JEMEN",
    "label": "Jemen"
  },
  {
    "value": "JERSEY",
    "label": "Jersey"
  },
  {
    "value": "JORDANIA",
    "label": "Jordania"
  },
  {
    "value": "KAJMANY",
    "label": "Kajmany"
  },
  {
    "value": "KAMBODżA",
    "label": "Kambodża"
  },
  {
    "value": "KAMERUN",
    "label": "Kamerun"
  },
  {
    "value": "KANADA",
    "label": "Kanada"
  },
  {
    "value": "KATAR",
    "label": "Katar"
  },
  {
    "value": "KAZACHSTAN",
    "label": "Kazachstan"
  },
  {
    "value": "KENIA",
    "label": "Kenia"
  },
  {
    "value": "KIRGISTAN",
    "label": "Kirgistan"
  },
  {
    "value": "KIRIBATI",
    "label": "Kiribati"
  },
  {
    "value": "KOLUMBIA",
    "label": "Kolumbia"
  },
  {
    "value": "KOMORY",
    "label": "Komory"
  },
  {
    "value": "KONGO",
    "label": "Kongo"
  },
  {
    "value": "KOREA POłUDNIOWA",
    "label": "Korea Południowa"
  },
  {
    "value": "KOREA PółNOCNA",
    "label": "Korea Północna"
  },
  {
    "value": "KOSTARYKA",
    "label": "Kostaryka"
  },
  {
    "value": "KUBA",
    "label": "Kuba"
  },
  {
    "value": "KUWEJT",
    "label": "Kuwejt"
  },
  {
    "value": "LAOS",
    "label": "Laos"
  },
  {
    "value": "LESOTHO",
    "label": "Lesotho"
  },
  {
    "value": "LIBAN",
    "label": "Liban"
  },
  {
    "value": "LIBERIA",
    "label": "Liberia"
  },
  {
    "value": "LIBIA",
    "label": "Libia"
  },
  {
    "value": "LIECHTENSTEIN",
    "label": "Liechtenstein"
  },
  {
    "value": "LITWA",
    "label": "Litwa"
  },
  {
    "value": "LUKSEMBURG",
    "label": "Luksemburg"
  },
  {
    "value": "ŁOTWA",
    "label": "Łotwa"
  },
  {
    "value": "MACEDONIA",
    "label": "Macedonia"
  },
  {
    "value": "MADAGASKAR",
    "label": "Madagaskar"
  },
  {
    "value": "MAJOTTA",
    "label": "Majotta"
  },
  {
    "value": "MAKAU",
    "label": "Makau"
  },
  {
    "value": "MALAWI",
    "label": "Malawi"
  },
  {
    "value": "MALEDIWY",
    "label": "Malediwy"
  },
  {
    "value": "MALEZJA",
    "label": "Malezja"
  },
  {
    "value": "MALI",
    "label": "Mali"
  },
  {
    "value": "MALTA",
    "label": "Malta"
  },
  {
    "value": "MARIANY PółNOCNE",
    "label": "Mariany Północne"
  },
  {
    "value": "MAROKO",
    "label": "Maroko"
  },
  {
    "value": "MARTYNIKA",
    "label": "Martynika"
  },
  {
    "value": "MAURETANIA",
    "label": "Mauretania"
  },
  {
    "value": "MAURITIUS",
    "label": "Mauritius"
  },
  {
    "value": "MEKSYK",
    "label": "Meksyk"
  },
  {
    "value": "MIKRONEZJA",
    "label": "Mikronezja"
  },
  {
    "value": "MJANMA",
    "label": "Mjanma"
  },
  {
    "value": "MOłDAWIA",
    "label": "Mołdawia"
  },
  {
    "value": "MONAKO",
    "label": "Monako"
  },
  {
    "value": "MONGOLIA",
    "label": "Mongolia"
  },
  {
    "value": "MONTSERRAT",
    "label": "Montserrat"
  },
  {
    "value": "MOZAMBIK",
    "label": "Mozambik"
  },
  {
    "value": "NAMIBIA",
    "label": "Namibia"
  },
  {
    "value": "NAURU",
    "label": "Nauru"
  },
  {
    "value": "NEPAL",
    "label": "Nepal"
  },
  {
    "value": "NIEMCY",
    "label": "Niemcy"
  },
  {
    "value": "NIGER",
    "label": "Niger"
  },
  {
    "value": "NIGERIA",
    "label": "Nigeria"
  },
  {
    "value": "NIKARAGUA",
    "label": "Nikaragua"
  },
  {
    "value": "NIUE",
    "label": "Niue"
  },
  {
    "value": "NORFOLK",
    "label": "Norfolk"
  },
  {
    "value": "NORWEGIA",
    "label": "Norwegia"
  },
  {
    "value": "NOWA KALEDONIA",
    "label": "Nowa Kaledonia"
  },
  {
    "value": "NOWA ZELANDIA",
    "label": "Nowa Zelandia"
  },
  {
    "value": "OMAN",
    "label": "Oman"
  },
  {
    "value": "PAKISTAN",
    "label": "Pakistan"
  },
  {
    "value": "PALAU",
    "label": "Palau"
  },
  {
    "value": "PALESTYNA",
    "label": "Palestyna"
  },
  {
    "value": "PANAMA",
    "label": "Panama"
  },
  {
    "value": "PAPUA-NOWA GWINEA",
    "label": "Papua-Nowa Gwinea"
  },
  {
    "value": "PARAGWAJ",
    "label": "Paragwaj"
  },
  {
    "value": "PERU",
    "label": "Peru"
  },
  {
    "value": "PITCAIRN",
    "label": "Pitcairn"
  },
  {
    "value": "POLINEZJA FRANCUSKA",
    "label": "Polinezja Francuska"
  },
  {
    "value": "POLSKA",
    "label": "Polska"
  },
  {
    "value": "PORTORYKO",
    "label": "Portoryko"
  },
  {
    "value": "PORTUGALIA",
    "label": "Portugalia"
  },
  {
    "value": "REPUBLIKA POłUDNIOWEJ AFRYKI",
    "label": "Republika Południowej Afryki"
  },
  {
    "value": "REPUBLIKA ŚRODKOWOAFRYKAńSKA",
    "label": "Republika Środkowoafrykańska"
  },
  {
    "value": "REPUBLIKA ZIELONEGO PRZYLąDKA",
    "label": "Republika Zielonego Przylądka"
  },
  {
    "value": "REUNION",
    "label": "Reunion"
  },
  {
    "value": "ROSJA",
    "label": "Rosja"
  },
  {
    "value": "RUMUNIA",
    "label": "Rumunia"
  },
  {
    "value": "RWANDA",
    "label": "Rwanda"
  },
  {
    "value": "SAHARA ZACHODNIA",
    "label": "Sahara Zachodnia"
  },
  {
    "value": "SAINT KITTS I NEVIS",
    "label": "Saint Kitts i Nevis"
  },
  {
    "value": "SAINT LUCIA",
    "label": "Saint Lucia"
  },
  {
    "value": "SAINT VINCENT I GRENADYNY",
    "label": "Saint Vincent i Grenadyny"
  },
  {
    "value": "SAINT-BARTHéLEMY",
    "label": "Saint-Barthélemy"
  },
  {
    "value": "SAINT-MARTIN",
    "label": "Saint-Martin"
  },
  {
    "value": "SAINT-PIERRE I MIQUELON",
    "label": "Saint-Pierre i Miquelon"
  },
  {
    "value": "SALWADOR",
    "label": "Salwador"
  },
  {
    "value": "SAMOA AMERYKAńSKIE",
    "label": "Samoa Amerykańskie"
  },
  {
    "value": "SAMOA",
    "label": "Samoa"
  },
  {
    "value": "SAN MARINO",
    "label": "San Marino"
  },
  {
    "value": "SENEGAL",
    "label": "Senegal"
  },
  {
    "value": "SERBIA",
    "label": "Serbia"
  },
  {
    "value": "SESZELE",
    "label": "Seszele"
  },
  {
    "value": "SIERRA LEONE",
    "label": "Sierra Leone"
  },
  {
    "value": "SINGAPUR",
    "label": "Singapur"
  },
  {
    "value": "SINT MAARTEN",
    "label": "Sint Maarten"
  },
  {
    "value": "SłOWACJA",
    "label": "Słowacja"
  },
  {
    "value": "SłOWENIA",
    "label": "Słowenia"
  },
  {
    "value": "SOMALIA",
    "label": "Somalia"
  },
  {
    "value": "SRI LANKA",
    "label": "Sri Lanka"
  },
  {
    "value": "STANY ZJEDNOCZONE",
    "label": "Stany Zjednoczone"
  },
  {
    "value": "ESWATINI",
    "label": "Eswatini"
  },
  {
    "value": "SUDAN",
    "label": "Sudan"
  },
  {
    "value": "SUDAN POłUDNIOWY",
    "label": "Sudan Południowy"
  },
  {
    "value": "SURINAM",
    "label": "Surinam"
  },
  {
    "value": "SVALBARD I JAN MAYEN",
    "label": "Svalbard i Jan Mayen"
  },
  {
    "value": "SYRIA",
    "label": "Syria"
  },
  {
    "value": "SZWAJCARIA",
    "label": "Szwajcaria"
  },
  {
    "value": "SZWECJA",
    "label": "Szwecja"
  },
  {
    "value": "TADżYKISTAN",
    "label": "Tadżykistan"
  },
  {
    "value": "TAJLANDIA",
    "label": "Tajlandia"
  },
  {
    "value": "TAJWAN",
    "label": "Tajwan"
  },
  {
    "value": "TANZANIA",
    "label": "Tanzania"
  },
  {
    "value": "TIMOR WSCHODNI",
    "label": "Timor Wschodni"
  },
  {
    "value": "TOGO",
    "label": "Togo"
  },
  {
    "value": "TOKELAU",
    "label": "Tokelau"
  },
  {
    "value": "TONGA",
    "label": "Tonga"
  },
  {
    "value": "TRYNIDAD I TOBAGO",
    "label": "Trynidad i Tobago"
  },
  {
    "value": "TUNEZJA",
    "label": "Tunezja"
  },
  {
    "value": "TURCJA",
    "label": "Turcja"
  },
  {
    "value": "TURKMENISTAN",
    "label": "Turkmenistan"
  },
  {
    "value": "TURKS I CAICOS",
    "label": "Turks i Caicos"
  },
  {
    "value": "TUVALU",
    "label": "Tuvalu"
  },
  {
    "value": "UGANDA",
    "label": "Uganda"
  },
  {
    "value": "UKRAINA",
    "label": "Ukraina"
  },
  {
    "value": "URUGWAJ",
    "label": "Urugwaj"
  },
  {
    "value": "UZBEKISTAN",
    "label": "Uzbekistan"
  },
  {
    "value": "VANUATU",
    "label": "Vanuatu"
  },
  {
    "value": "WALLIS I FUTUNA",
    "label": "Wallis i Futuna"
  },
  {
    "value": "WATYKAN",
    "label": "Watykan"
  },
  {
    "value": "WENEZUELA",
    "label": "Wenezuela"
  },
  {
    "value": "WęGRY",
    "label": "Węgry"
  },
  {
    "value": "WIELKA BRYTANIA",
    "label": "Wielka Brytania"
  },
  {
    "value": "WIETNAM",
    "label": "Wietnam"
  },
  {
    "value": "WłOCHY",
    "label": "Włochy"
  },
  {
    "value": "WYBRZEżE KOśCI SłONIOWEJ",
    "label": "Wybrzeże Kości Słoniowej"
  },
  {
    "value": "WYSPA BOUVETA",
    "label": "Wyspa Bouveta"
  },
  {
    "value": "WYSPA BOżEGO NARODZENIA",
    "label": "Wyspa Bożego Narodzenia"
  },
  {
    "value": "WYSPA MAN",
    "label": "Wyspa Man"
  },
  {
    "value": "WYSPA ŚWIęTEJ HELENY, WYSPA WNIEBOWSTąPIENIA I TRISTAN DA CUNHA",
    "label": "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha"
  },
  {
    "value": "WYSPY ALANDZKIE",
    "label": "Wyspy Alandzkie"
  },
  {
    "value": "WYSPY COOKA",
    "label": "Wyspy Cooka"
  },
  {
    "value": "WYSPY DZIEWICZE STANóW ZJEDNOCZONYCH",
    "label": "Wyspy Dziewicze Stanów Zjednoczonych"
  },
  {
    "value": "WYSPY HEARD I MCDONALDA",
    "label": "Wyspy Heard i McDonalda"
  },
  {
    "value": "WYSPY KOKOSOWE",
    "label": "Wyspy Kokosowe"
  },
  {
    "value": "WYSPY MARSHALLA",
    "label": "Wyspy Marshalla"
  },
  {
    "value": "WYSPY OWCZE",
    "label": "Wyspy Owcze"
  },
  {
    "value": "WYSPY SALOMONA",
    "label": "Wyspy Salomona"
  },
  {
    "value": "WYSPY ŚWIęTEGO TOMASZA I KSIążęCA",
    "label": "Wyspy Świętego Tomasza i Książęca"
  },
  {
    "value": "ZAMBIA",
    "label": "Zambia"
  },
  {
    "value": "ZIMBABWE",
    "label": "Zimbabwe"
  },
  {
    "value": "ZJEDNOCZONE EMIRATY ARABSKIE",
    "label": "Zjednoczone Emiraty Arabskie"
  }
]

export class Address {
  id: number;
  user: User;
  is_business: boolean;
  name_first: string;
  name_last: string;
  company_name: string;
  street_name: string;
  street_number: string;
  flat_number: string;
  zip_code: string;
  city: string;
  country: string;
  vat_number: string;
  phone_number: string;

  constructor() {
  }
}

export class AddressHelper {
  public singleString(address: Address) : string {
    let retVal : string = '';

    retVal += address.name_first + " " + address.name_last + ", ";

    if (address.is_business && address.company_name.trim() !== ''){
      retVal += '"' + address.company_name + '",';
    }

    if (address.street_name.trim() !== ''){
      retVal += " " + address.street_name;
    }

    if (address.street_number.trim() !== ''){
      retVal += " " + address.street_number;
    }

    if (address.flat_number.trim() !== ''){
      retVal += " / " + address.flat_number;
    }

    if (address.zip_code.trim() !== ''){
      retVal += ", " + address.zip_code + " " + address.city.trim();
    }

    if (address.country.trim() !== ''){
      retVal += ", " + address.country;
    }

    return retVal;
  }

  public singleStringWithoutName(address: Address) : string {
    let retVal : string = '';

    if (address.street_name.trim() !== ''){
      retVal += " " + address.street_name;
    }

    if (address.street_number.trim() !== ''){
      retVal += " " + address.street_number;
    }

    if (address.flat_number.trim() !== ''){
      retVal += " / " + address.flat_number;
    }

    if (address.zip_code.trim() !== ''){
      retVal += ", " + address.zip_code + " " + address.city.trim();
    }

    if (address.country.trim() !== ''){
      retVal += ", " + address.country;
    }

    return retVal;
  }
}

/*
export const Address_EmptyEntity = <Address>{
  id: -1, //-1 means this is a new address which needs to be added to database!!!
  user: null,
  is_business: false,
  name_first: "",
  name_last: "",
  company_name: "",
  street_name: "",
  street_number: "",
  flat_number: "",
  zip_code: "",
  city: "",
  country: "",
  vat_number: "",
  phone_number: "",
};
*/

export const Address_EmptyEntity = {
  id: -1, // -1 means this is a new address which needs to be added to database!!!
  user: null,
  is_business: false,
  name_first: "",
  name_last: "",
  company_name: "",
  street_name: "",
  street_number: "",
  flat_number: "",
  zip_code: "",
  city: "",
  country: "Polska",
  vat_number: "",
  phone_number: "",
} as Address;
