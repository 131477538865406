<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
    </button>
    <h4 class="modal-title w-100" id="myModalLabel">Edycja adresu</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" *ngIf="error_message" [innerHTML]="error_message|keepHtml"></div>
    </div>
  </div>

    <form [formGroup]="addressEditForm">
        <div class="form-row">
            <div class="col-12 col-md-4">
                <div class="md-form mb-2">
                    <input type="text" id="addressEditModalForm-name_first" [formControl]="addressEditModalNameFirst" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-name_first">Imię</label>
                    <mdb-error *ngIf="addressEditModalNameFirst.invalid && (addressEditModalNameFirst.dirty || addressEditModalNameFirst.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalNameFirst.valid && (addressEditModalNameFirst.dirty || addressEditModalNameFirst.touched)">OK</mdb-success>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="md-form">
                    <input type="text" id="addressEditModalForm-name_last" [formControl]="addressEditModalNameLast" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-name_last">Nazwisko</label>
                    <mdb-error *ngIf="addressEditModalNameLast.invalid && (addressEditModalNameLast.dirty || addressEditModalNameLast.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalNameLast.valid && (addressEditModalNameLast.dirty || addressEditModalNameLast.touched)">OK</mdb-success>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="md-form mb-2">
                    <input type="text" id="addressEditModalForm-phone_number" [formControl]="addressEditModalPhoneNumber" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-phone_number">Telefon kontaktowy</label>
                    <mdb-error *ngIf="addressEditModalPhoneNumber.invalid && (addressEditModalPhoneNumber.dirty || addressEditModalPhoneNumber.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalPhoneNumber.valid && (addressEditModalPhoneNumber.dirty || addressEditModalPhoneNumber.touched)">OK</mdb-success>
                </div>
            </div>
        </div>


        <div class="form-row">
            <div class="col-12">
                <div class="md-form">
                    <div class="form-group mt-4 mb-2">
                        <mdb-checkbox (change)="onIsBusinessClick($event)" [checked]="f.addressEditModalIsBusiness.value">Zaznacz, jeśli jest to adres firmowy</mdb-checkbox>
                        <input class="form-check-input" type="checkbox" id="addressEditModalForm-is_business" [formControl]="addressEditModalIsBusiness" >
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isBusiness" class="form-row">
            <div class="col-12 col-md-6">
                <div class="md-form mb-2 mt-2">
                    <input type="text" id="addressEditModalForm-company_name" [formControl]="addressEditModalCompanyName" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-company_name">Nazwa firmy</label>
                    <mdb-error *ngIf="addressEditModalCompanyName.invalid && (addressEditModalCompanyName.dirty || addressEditModalCompanyName.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalCompanyName.valid && (addressEditModalCompanyName.dirty || addressEditModalCompanyName.touched)">OK</mdb-success>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="md-form mb-2 mt-2">
                    <input type="text" id="addressEditModalForm-vat_number" [formControl]="addressEditModalVatNumber" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-vat_number">Numer NIP</label>
                    <mdb-error *ngIf="addressEditModalVatNumber.invalid && (addressEditModalVatNumber.dirty || addressEditModalVatNumber.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalVatNumber.valid && (addressEditModalVatNumber.dirty || addressEditModalVatNumber.touched)">OK</mdb-success>
                </div>
            </div>
        </div>

      <div class="form-row">
        <div class="col-4 col-md-2">
          <div class="md-form mb-2">
            <input type="text" id="addressEditModalForm-zip_code" [formControl]="addressEditModalZipCode" class="form-control" mdbInput mdbValidate>
            <label for="addressEditModalForm-zip_code">Kod pocztowy</label>
            <mdb-error *ngIf="addressEditModalZipCode.invalid && (addressEditModalZipCode.dirty || addressEditModalZipCode.touched)">Błąd</mdb-error>
            <mdb-success *ngIf="addressEditModalZipCode.valid && (addressEditModalZipCode.dirty || addressEditModalZipCode.touched)">OK</mdb-success>
          </div>
        </div>
        <div class="col-8 col-md-5">
          <div class="md-form mb-2">
            <input type="text" id="addressEditModalForm-city" [formControl]="addressEditModalCity" class="form-control" mdbInput mdbValidate>
            <label for="addressEditModalForm-city">Miasto</label>
            <mdb-error *ngIf="addressEditModalCity.invalid && (addressEditModalCity.dirty || addressEditModalCity.touched)">Błąd</mdb-error>
            <mdb-success *ngIf="addressEditModalCity.valid && (addressEditModalCity.dirty || addressEditModalCity.touched)">OK</mdb-success>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div class="md-form mb-2">
            <mdb-select id="addressEditModalForm-country-dropdown" [options]="countriesList" [formControl]="addressEditModalCountrySelect" placeholder="Wybierz kraj"></mdb-select>
            <label for="addressEditModalForm-country-dropdown">Kraj</label>

            <mdb-error *ngIf="addressEditModalCountrySelect.invalid && (addressEditModalCountrySelect.dirty || addressEditModalCountrySelect.touched)">Błąd</mdb-error>
            <mdb-success *ngIf="addressEditModalCountrySelect.valid && (addressEditModalCountrySelect.dirty || addressEditModalCountrySelect.touched)">OK</mdb-success>
          </div>
        </div>
      </div>

        <div class="form-row">
            <div class="col-12 col-md-8">
                <div class="md-form mb-2">
                    <input type="text" id="addressEditModalForm-street_name" [formControl]="addressEditModalStreetName" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-street_name">Ulica</label>
                    <mdb-error *ngIf="addressEditModalStreetName.invalid && (addressEditModalStreetName.dirty || addressEditModalStreetName.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalStreetName.valid && (addressEditModalStreetName.dirty || addressEditModalStreetName.touched)">OK</mdb-success>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="md-form mb-2">
                    <input type="text" id="addressEditModalForm-street_number" [formControl]="addressEditModalStreetNumber" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-street_number">Numer</label>
                    <mdb-error *ngIf="addressEditModalStreetNumber.invalid && (addressEditModalStreetNumber.dirty || addressEditModalStreetNumber.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalStreetNumber.valid && (addressEditModalStreetNumber.dirty || addressEditModalStreetNumber.touched)">OK</mdb-success>
                </div>
            </div>
            <div class="col-6 col-md-2">
                <div class="md-form mb-2">
                    <input type="text" id="addressEditModalForm-flat_number" [formControl]="addressEditModalFlatNumber" class="form-control" mdbInput mdbValidate>
                    <label for="addressEditModalForm-flat_number">Lokal</label>
                    <mdb-error *ngIf="addressEditModalFlatNumber.invalid && (addressEditModalFlatNumber.dirty || addressEditModalFlatNumber.touched)">Błąd</mdb-error>
                    <mdb-success *ngIf="addressEditModalFlatNumber.valid && (addressEditModalFlatNumber.dirty || addressEditModalFlatNumber.touched)">OK</mdb-success>
                </div>
            </div>
        </div>

      <div class="form-row">
        <div class="col-12 mt-2">
          <!--
            The message below is here to create a space between the form and the button used when dropdown list of countries is displayed.
          //-->
          <h6 style="color: #B0BEC5">
            Prosimy o dokładne wypełnienie formularza - w tym wpisanie poprawnego kodu pocztowego (dla Polski jest to format
            XX-XXX) oraz wybranie kraju z listy.
          </h6>
        </div>
      </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" mdbBtn color="secondary" size="sm" class="waves-light" aria-label="Close" (click)="modalRef.hide()" mdbWavesEffect>Anuluj</button>
    <button type="button" mdbBtn color="primary" size="sm" class="relative waves-light" [disabled]="!addressEditForm.valid" (click)="saveData()" mdbWavesEffect>Zapisz zmiany</button>
</div>
